.access-div {
  color: #1E1E1E;

  &.even {
    background: #FFF;
  }

  &.odd {
    background: #F1F1F1;
  }
}

.group-header {
  background: #3D4246;
  color: #FFF;
  margin: 0 0 20px;

  .group-title {
    font-weight: bold;
    text-transform: capitalize;
  }

  i {
    cursor: pointer;
  }
}
