.mi {
  color: $color_log_cabin_approx;
  font-family: $fontFamily;
  font-size: 14px;
  line-height: 1.4;

  img {
    border: 0;
    vertical-align: middle;
  }

  .ag-header-container {
    text-transform: uppercase;
  }

  .grid-sales-button {
    cursor: pointer;

    span {
      &:hover {
        color: $defaultThemeColor;
      }
    }
  }

  .ag-header-cell-label {
    background: $gridSelectorHighliterColor;
    border-radius: 3px;
    color: $actionButtonDeactivate;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-left: 0;
    opacity: .7;
    padding: 10px;
  }

  .ag-root {
    border: 0 none;
  }

  .ag-body-viewport {
    overflow-x: hidden;
  }

  .ag-cell-not-inline-editing {
    line-height: 46px;
    padding: 16px 20px;
    text-align: left;
  }

  .ag-cell-focus {
    border: 0;
  }

  .ag-cell-no-focus {
    border-bottom: 1px solid $gridSelectorHighliterColor;
    border-top: 1px solid transparent;
  }

  .ag-header-cell-resize {
    background: $mainContentBgColor;
  }

  .ag-ltr {
    .ag-cell-no-focus {
      border-left: 1px solid transparent;
      border-right: 1px solid $gridSelectorHighliterColor;
    }

    .ag-cell-first-right-pinned {
      border-left: 1px solid $gridSelectorHighliterColor;
    }

    .ag-cell-last-left-pinned {
      border-right: 1px solid $gridSelectorHighliterColor;
    }

    .ag-header-cell {
      border-right: 0 none;
    }

    .ag-header-group-cell {
      border-right: 1px solid $gridSelectorHighliterColor;
    }

    .ag-header-group-cell-label {
      padding-left: 10px;
    }

    .ag-header-group-text {
      margin-right: 2px;
    }

    .ag-pinned-right-header {
      border-left: 1px solid $gridSelectorHighliterColor;
    }

    .ag-group-expanded {
      padding-right: 4px;
    }

    .ag-group-contracted {
      padding-right: 4px;
    }

    .ag-group-loading {
      padding-right: 4px;
    }

    .ag-group-value {
      padding-right: 2px;
    }

    .ag-group-checkbox {
      padding-right: 2px;
    }

    .ag-selection-checkbox {
      padding-right: 4px;
    }
  }

  .ag-rtl {
    .ag-cell-no-focus {
      border-left: 1px dotted $gridSelectorHighliterColor;
      border-right: 2px solid transparent;
    }

    .ag-cell-first-right-pinned {
      border-left: 1px solid $gridSelectorHighliterColor;
    }

    .ag-cell-last-left-pinned {
      border-right: 1px solid $gridSelectorHighliterColor;
    }

    .ag-header-cell {
      border-left: 1px solid $gridSelectorHighliterColor;
    }

    .ag-header-group-cell {
      border-left: 1px solid $gridSelectorHighliterColor;
    }

    .ag-header-group-cell-label {
      padding-right: 10px;
    }

    .ag-header-group-text {
      margin-left: 2px;
    }

    .ag-pinned-left-header {
      border-right: 1px solid $gridSelectorHighliterColor;
    }

    .ag-group-expanded {
      padding-left: 4px;
    }

    .ag-group-contracted {
      padding-left: 4px;
    }

    .ag-group-loading {
      padding-left: 4px;
    }

    .ag-group-value {
      padding-left: 2px;
    }

    .ag-group-checkbox {
      padding-left: 2px;
    }

    .ag-selection-checkbox {
      padding-left: 4px;
    }
  }

  .ag-cell-highlight {
    border: 1px solid $color_japanese_laurel_approx;
  }

  .ag-cell-highlight-animation {
    transition: border 1s;
  }

  .ag-value-change-delta {
    padding-right: 2px;
  }

  .ag-value-change-delta-up {
    color: $color_japanese_laurel_approx;
  }

  .ag-value-change-delta-down {
    color: $color_red_berry_approx;
  }

  .ag-value-change-value {
    background-color: transparent;
    border-radius: 1px;
    padding-left: 1px;
    padding-right: 1px;
    transition: background-color 1s;
  }

  .ag-value-change-value-highlight {
    background-color: $color_surf_crest_approx;
    transition: background-color .1s;
  }

  .ag-rich-select {
    background-color: $white;
    border: 1px solid $gridSelectorHighliterColor;
    font-size: 14px;
  }

  .ag-rich-select-value {
    padding: 2px;
  }

  .ag-rich-select-list {
    border-top: 1px solid $color_quill_gray_approx;
  }

  .ag-rich-select-row {
    padding: 2px;
  }

  .ag-rich-select-row-selected {
    background-color: $color_pumice_approx;
  }

  .ag-large-text {
    border: 1px solid $gridSelectorHighliterColor;
  }

  .ag-header-select-all {
    line-height: 0;
    padding: 5px 0 0 3px;
  }

  .ag-header-icon {
    color: $gridHeaderTextColor;
    fill: $gridHeaderTextColor;
    fill-opacity: .7;
    stroke: none;
  }

  .ag-header-cell-moving {
    background-color: $color_silver_approx;

    .ag-header-cell-label {
      padding: 10px;
    }
  }

  .ag-header-group-cell-with-group {
    border-bottom: 1px solid $gridSelectorHighliterColor;
  }

  .ag-header-cell-text {
    padding-left: 2px;
  }

  .ag-header-group-cell-label {
    padding: 4px;
  }

  .ag-header-cell-menu-button {
    border: 1px solid transparent;
    border-radius: 3px;
    box-sizing: content-box;
    line-height: 0;
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 4px;
    padding: 2px;

    &:hover {
      border: 1px solid $gridSelectorHighliterColor;
    }
  }

  .ag-body {
    background-color: $color_black_haze_approx;
  }

  .ag-row {
    color: $gridHeaderTextColor;

    &:hover {
      background: $gridSelectedRowHover;
    }
  }

  .ag-row-odd {
    background-color: $gridOddRowColor;
  }

  .ag-row-even {
    background-color: $white;
  }

  .vip-passenger {
    background-color: $sidebarIconColor;
  }

  .ag-row-selected {
    background-color: $gridRowSelectedColor;
  }

  .ag-overlay-loading-wrapper {
    background-color: $white_50;
  }

  .ag-overlay-loading-center {
    background-color: $white;
    border: 1px solid $gridSelectorHighliterColor;
    border-radius: 10px;
    color: $black;
    padding: 10px;
  }

  .ag-overlay-no-rows-center {
    background-color: $white;
    border: 1px solid $gridSelectorHighliterColor;
    border-radius: 10px;
    padding: 10px;
  }

  .ag-group-cell-entire-row {
    background-color: $color_black_haze_approx;
    padding: 2px;
  }

  .ag-footer-cell-entire-row {
    background-color: $color_black_haze_approx;
    padding: 2px;
  }

  .ag-group-cell {
    font-style: italic;
  }

  .ag-group-child-count {
    display: inline-block;
  }

  .ag-footer-cell {
    font-style: italic;
  }

  .ag-menu {
    background-color: $color_black_haze_approx;
    border: 1px solid $gray;
    cursor: default;
    font-family: $fontFamily;
    font-size: 10pt;

    .ag-tab-header {
      background-color: $color_danube_approx;
    }

    .ag-tab {
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-top: 1px solid transparent;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      display: inline-block;
      margin: 2px 2px 0;
      padding: 6px 8px;
    }

    .ag-tab-selected {
      background-color: $gridSelectorHighliterColor;
      border-left: 1px solid $color_quill_gray_approx;
      border-right: 1px solid $color_quill_gray_approx;
      border-top: 1px solid $color_quill_gray_approx;
    }
  }

  .ag-menu-separator {
    border-top: 1px solid $color_quill_gray_approx;
  }

  .ag-menu-option-active {
    background-color: $color_pumice_approx;
  }

  .ag-menu-option-icon {
    padding: 2px 4px;
    vertical-align: middle;
  }

  .ag-menu-option-text {
    padding: 2px 4px;
    vertical-align: middle;
  }

  .ag-menu-option-shortcut {
    padding: 2px;
    vertical-align: middle;
  }

  .ag-menu-option-popup-pointer {
    padding: 2px 4px;
    vertical-align: middle;
  }

  .ag-menu-option-disabled {
    filter: alpha(opacity=50);
    opacity: .5;
  }

  .ag-menu-column-select-wrapper {
    margin: 2px;
  }

  .ag-filter-checkbox {
    left: 2px;
    position: relative;
    top: 2px;
  }

  .ag-filter-header-container {
    border-bottom: 1px solid $color_quill_gray_approx;
  }

  .ag-filter-apply-panel {
    border-top: 1px solid $color_quill_gray_approx;
    padding: 2px;
  }

  .ag-filter-value {
    margin-left: 4px;
  }

  .ag-paging-panel {
    cursor: pointer;
    font-size: 15px;
    line-height: 2;
    margin-bottom: 15px;
    padding: 8px 0;

    .row-label {
      color: $defaultThemeColor;
    }
  }

  .ag-paging-page-summary-panel {
    float: right;

    .ag-paging-button {
      background-color: $gridHeaderBgColor;
      border: 0;
      border-radius: 5px;
      color: $paginationTextColor;
      cursor: pointer;
      font-size: 15px;
      padding: 8px 20px 8px 15px;

      &:disabled {
        color: #777;
        cursor: not-allowed;
      }

      &.bt-next {
        margin-left: 8px;

        &::after {
          content: '\f178';
          font-family: $fontFamily;
          margin-left: 5px;
        }
      }

      &.bt-previous {
        margin-right: 8px;

        &::before {
          content: '\f177';
          font-family: $fontFamily;
          margin-right: 5px;
        }
      }

      &.bt-last,
      &.bt-first {
        display: none;
      }

      &.lb-current {
        background: $taxipulseThemeColor;
        border-radius: 24px;
        color: $white;
        line-height: 1;
        margin: 0 10px;
        padding: 6px 10px;
        stroke: none;
      }
    }
  }

  .ag-paging-row-summary-panel {
    display: inline-block;
    width: 300px;
  }

  .ag-tool-panel {
    background-color: $color_black_haze_approx;
    border-bottom: 1px solid $gridSelectorHighliterColor;
    border-top: 1px solid $gridSelectorHighliterColor;
    color: $color_log_cabin_approx;

    .ag-column-select-panel {
      border-bottom: 1px solid $gridSelectorHighliterColor;
    }
  }

  .ag-status-bar {
    background-color: $color_black_haze_approx;
    border-bottom: 1px solid $gridSelectorHighliterColor;
    border-left: 1px solid $gridSelectorHighliterColor;
    border-right: 1px solid $gridSelectorHighliterColor;
    color: $color_log_cabin_approx;
    font-size: 10pt;
    height: 22px;
    padding: 2px;
  }

  .ag-status-bar-aggregations {
    float: right;
  }

  .ag-status-bar-item {
    padding-left: 10px;
  }

  .ag-column-drop-cell {
    background: $gridOddRowColor;
    border: 1px solid $gray;
    color: $black;
  }

  .ag-column-drop-cell-ghost {
    filter: alpha(opacity=50);
    opacity: .5;
  }

  .ag-column-drop-cell-text {
    padding-left: 2px;
    padding-right: 2px;
  }

  .ag-column-drop-cell-button {
    border: 1px solid transparent;
    border-radius: 3px;
    padding-left: 2px;
    padding-right: 2px;

    &:hover {
      border: 1px solid $gridSelectorHighliterColor;
    }
  }

  .ag-column-drop-empty-message {
    color: $gray;
    padding-left: 2px;
    padding-right: 2px;
  }

  .ag-column-drop-icon {
    margin: 3px;
  }

  .ag-column-drop {
    background-color: $color_black_haze_approx;
  }

  .ag-column-drop-horizontal {
    border-left: 1px solid $gridSelectorHighliterColor;
    border-right: 1px solid $gridSelectorHighliterColor;
    border-top: 1px solid $gridSelectorHighliterColor;
    padding: 2px;
  }

  .ag-column-drop-vertical {
    border-bottom: 1px solid $gridSelectorHighliterColor;
    padding: 4px 4px 10px;

    .ag-column-drop-cell {
      margin-top: 2px;
    }

    .ag-column-drop-empty-message {
      padding: 5px;
      text-align: center;
    }
  }

  .ag-pivot-mode {
    background-color: $color_black_haze_approx;
    border-bottom: 1px solid $gridSelectorHighliterColor;
    padding: 4px;
  }

  .ag-select-agg-func-popup {
    background-color: $white;
    border: 1px solid $gridSelectorHighliterColor;
    cursor: default;
    font-size: 14px;
    position: absolute;
  }

  .ag-select-agg-func-item {
    padding-left: 2px;
    padding-right: 2px;

    &:hover {
      background-color: $color_pumice_approx;
    }
  }

  .ag-floating-filter-button {
    background-color: $white;
    color: $color_log_cabin_approx;
  }

  .ag-cell-range-selected-1 {
    &:not(.ag-cell-focus) {
      background-color: $color_boulder_40_approx;
    }
  }

  .ag-cell-range-selected-2 {
    &:not(.ag-cell-focus) {
      background-color: $color_fuscous_gray_40_approx;
    }
  }

  .ag-cell-range-selected-3 {
    &:not(.ag-cell-focus) {
      background-color: $color_shark_40_approx;
    }
  }

  .ag-cell-range-selected-4 {
    :not(.ag-cell-focus) {
      background-color: $black_40;
    }
  }

  .ag-no-scrolls {
    &.ag-header-container {
      background: $color_danube_approx;
      border-bottom: 1px solid $gridSelectorHighliterColor;
      text-transform: uppercase;
    }
  }

  .ag-floating-top {
    &.ag-row {
      background-color: $color_gallery_approx;
    }
  }

  .ag-floating-bottom {
    &.ag-row {
      background-color: $color_gallery_approx;
    }
  }

  .ltr {
    &.ag-tool-panel {
      border-right: 1px solid $gridSelectorHighliterColor;
    }
  }

  .rtl {
    &.ag-tool-panel {
      border-left: 1px solid $gridSelectorHighliterColor;
    }
  }

  .ag-floating-filter-body {
    input {
      background-color: $white;
      color: $color_log_cabin_approx;
    }
  }
}

.grid-comman-col {
  line-height: 1.5;
}

.grid-btn-div {
  text-align: center;
  width: 190px;

  &.buttons {
    color: #FFF;
    cursor: pointer;
    line-height: 30px;

    .new-request-btn {
      background-color: #F00;
    }

    .attended-btn {
      background-color: #808080;
    }

    .closed-btn {
      background-color: #080;
    }
  }
}

.grid-btn {
  color: $buttonDefaultBgColor;
  cursor: pointer;
  font-size: 18px;
  height: 33px;
  padding: 0 1px;
  vertical-align: middle;

  &.edit {
    color: $actionButtonDeactivate;

    &:hover {
      color: $defaultThemeColor;
    }

    .fa-pencil {
      opacity: .6;

      &.disabled {
        color: $sidebarIconColor;
        cursor: default;
      }
    }
  }

  &.reassign {
    color: $gridBtnColorSuccess;

    &:hover {
      color: $gridBtnColorSuccess;
    }
  }

  &.activate {
    color: $gridBtnColorDanger;

    &:hover {
      color: $gridBtnColorSuccess;
    }
  }

  &.deactivate {
    color: $gridBtnColorSuccess;

    &:hover {
      color: $gridBtnColorDangerFocus;
    }
  }

  &.assign {
    color: $actionButtonDeactivate;

    &:hover {
      color: $defaultThemeColor;
    }

    .fa-taxi {
      opacity: .6;

      &.disabled {
        color: $sidebarIconColor;
        cursor: default;
      }
    }

    .fa-user {
      opacity: .6;

      &.disabled {
        color: $sidebarIconColor;
        cursor: default;
      }
    }
  }

  &.save {
    color: $gridBtnColorSuccess;
  }

  &.cancel {
    color: $gridBtnColorDanger;
  }
}

.mi-grid-action-header {
  margin-bottom: 24px;

  .mi-grid-search {
    &:focus {
      border: 1px solid #E1E3E7;
      box-shadow: none;
      outline: none;
    }

    &:hover {
      border: 1px solid #E1E3E7;
    }

    // &.p-state-default {
      font-size: 14px;
      height: 24px;
      padding: 2px 12px 2px 30px;
    // }
  }

  .input-wrapper {
    display: inline-block;
    position: relative;
  }

  .input-icon {
    margin-left: 8px;
    opacity: .4;
    position: absolute;
    top: calc(50% - .5em);
  }

  .header-action-btn {
    background-color: $white;
    border: 1px solid $inputFieldBorderColor;
    border-radius: 5px;
    box-shadow: 0 3px 5px -2px rgba(0, 0, 0, .33);
    color: $defaultThemeColor;
    cursor: pointer;
    float: right;
    font-size: 14px;
    margin: 0 8px;
    padding: 7px 6px;
    vertical-align: middle;

    &:hover {
      background: $white;
      color: $gridFileHoverColor;
    }
  }
}

.common-col-secondary-text {
  opacity: .7;
}
