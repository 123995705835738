.mrg-30 {
  margin-top: 30px;
}

.corp-user-dialog {
  .display-image {
    .p-fileupload-buttonbar {
      background: $white;
    }
  }
}

.edit-profile {
  .form-field {
    input {
      &[type='text'] {
        border-bottom: 1px solid #ECEDF3;
        width: 100%;
      }
    }
  }

  .display-image {
    &.profile-btn {
      .p-fileupload-buttonbar {
        // background: $white;
      }
    }
  }
}

p-fileupload {
  .p-fileupload {
    .p-fileupload-buttonbar {
      background: transparent;
      border: 0;
    }

    .p-fileupload-content {
      display: none;
    }
  }
}

.form-field {
  .p-multiselect-filter-container {
    input {
      &[type='text'] {
        padding-left: 20px;
      }
    }
  }

  .lable-mobile-number {
    padding: 4px .5em;
  }

  .p-inputgroup {
    .p-inputgroup-addon {
      font-size: 10px;
      margin: 8px 0;
      padding: 9px 1px;
    }

    .input-mobile-code,
    .input {
      border: 1px solid $inputFieldBorderColor;
      height: 30px;
      min-width: 65%;
      padding: 0 4px;
    }
  }

  &.mt-15 {
    margin-top: 15px 0 0 0;
  }

  &.multiselect-text {

    input {
      &[type='text'] {
        padding: 0 18px;
      }
    }
  }

  p-rating {
    .p-rating {
      margin-top: 5px;

      a,
      &:active {
        font-size: 28px;
      }
    }
  }

  label {
    margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
  }

  textarea {
    border: 1px solid #ECEDF3;
    border-bottom: 1px solid #ECEDF3;
    font-family: $fontFamily;
    font-size: 14px;
    height: $inputTextHeight + 100;
    margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
    padding: 1% 2%;
    width: 100%;

    &:hover {
      border: 1px solid #ECEDF3;
      border-bottom: 1px solid #06A0FF;
    }
  }

  input {
    &[type='text'] {
      border: 0;
      border-bottom: 1px solid #ECEDF3;
      border-radius: 0;
      font-family: $fontFamily;
      font-size: 14px;
      height: $inputTextHeight;
      margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
      padding: 0;
      text-overflow: ellipsis;
      width: 100%;

      &.quarter-ui {
        width: 92%;
      }

      &:hover {
        border: 0;
        border-bottom: 1px solid #06A0FF;
      }
    }

    &[type='password'] {
      border: 0;
      border-bottom: 1px solid #ECEDF3;
      border-radius: 0;
      height: $inputTextHeight;
      margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
      padding: 0 2%;
      width: 100%;

      &:hover {
        border: 0;
        border-bottom: 1px solid #06A0FF;
      }
    }

    &::placeholder {
      font-size: 16px;
    }

    &:read-only {
      background: $readOnlyFieldColor;
    }

    &.full-width {
      width: 100%;
    }
  }

  .p-dropdown {
    background: $contentBgColor;
    border: 0;
    border-bottom: 1px solid #ECEDF3;
    border-radius: 0;

    &:not(.p-state-disabled) {
      &:hover {
        background-color: $contentBgColor;
        border-color: #06A0FF;
      }
    }
  }

  p-radiobutton {
    padding: 5% 5% 5% 0;
  }

  p-checkbox {
    padding: 5%;

    &.vip-chk {
      vertical-align: sub;

      .p-checkbox-label {
        padding-left: 7px;
      }
    }
  }

  .p-calendar {
    width: 100%;

    input {
      &[type='text'] {
        height: 30px;
        margin: 8px 0;
        padding: 0 2%;
        width: 96%;
      }
    }
  }

  p-selectbutton {
    .p-button {
      background: $mainContentBgColor;
      border: 1px solid $selectButtonBorderColor;
      color: $black;
    }

    .p-state-active {
      &.p-button {
        background: $defaultThemeColor;
        border-color: $contentBorderColor;
        color: $white;
      }
    }
  }
}

.display-image {
  &.profile-btn {
    .p-fileupload-buttonbar {
      background: $mainContentBgColor;
    }
  }

  .p-fileupload {
    .p-fileupload-content {
      display: none;
    }

    .p-fileupload-buttonbar {
      background: transparent;
      border: 0 none;
      right: 30px;
      position: relative;
      justify-content: center;
      display: flex;
    }
  }
}

.p-fileupload-buttonbar {
  padding: .5em 0;
}

.p-inputtext {
  &.ng-dirty {
    &.ng-invalid {
      border: 0;

      &:hover {
        border: 0;
        border-bottom: 1px solid #06A0FF;
      }
    }

    &.p-dropdown {
      height: $inputTextHeight;
      margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
      width: 96%;
    }
  }
}

.form-buttons {
  margin: 0 $formFieldLeftRightMargin;

  &.form-buttons-left-padded {
    margin: $formFieldTopBottomMargin -70px;
  }

  &.button-pad {
    padding-right: 5.4%;
  }

  button {
    display: block;
    float: right;
    margin: 8px 0;
    margin-right: 10px;
  }

  &::after {
    clear: both;
    content: '';
    display: block;
  }

  .p-button-text-icon-right {
    padding: 4px 1px;
  }
}

.p-dropdown {
  background: $contentBgColor;
  height: 32px;
  margin: 1px 0;
  min-width: 100%;

  &:not(.p-state-disabled) {
    &:hover {
      background-color: $contentBgColor;
      border-color: $stateFocusBorderColor;
    }
  }

  .p-button {
    border: 0;
  }

  .p-dropdown-label {
    margin: 0;
    padding: 7px 8px;
    text-overflow: ellipsis;
  }

  .p-dropdown-trigger {

    .fa {
      margin-left: -3px;
      margin-top: 8px;
    }
  }
}

.p-fileupload-content {
  border: 0;
  padding: 0;

  .p-messages-icon  {
    display: none;
  }

  .p-fileupload-files {
    display: none;
  }

  .p-messages-detail {
    display: none;
  }

  .p-progressbar {
    display: none;
  }
}

.right-aligned-text {
  text-align: right;
}

.add-routes-popup {
  height: auto;
  width: 71%;
}



.add-popup {
  height: auto;
  width: 35%;
}

fieldset {
  &.p-widget-content {
    legend {
      &.p-button {
        background: $mainContentBgColor;
        color: $contentTextColor;
      }
    }
  }
}

.p-fieldset {
  &.p-fieldset-toggleable {
    .p-fieldset-legend {
      &:hover {
        background: $mainContentBgColor;
        border-color: $inputFieldBorderColor;
        color: $contentTextColor;
      }
    }
  }
}

.p-multiselect {
  margin: 8px 0;
  padding-right: 2%;
  width: 100%;

  &:not(.p-state-disabled) {

    &:hover {
      background-color: $contentBgColor;
      border-color: $stateFocusBorderColor;
      color: $contentTextColor;
    }
  }

  &.p-button {
    background: $contentBgColor;
    color: $contentTextColor;
  }

  .p-multiselect-trigger {
    .fa {
      margin-left: -3px;
      margin-top: 8px;
    }
  }
}

.ng-dirty {
  &.ng-invalid {
    border: 0;

    .p-multiselect {
      &:not(.p-state-disabled) {
        &:hover {
          border-color: $stateFocusBorderColor;
        }
      }
    }
  }
}

.p-multiselect-header {
  .p-multiselect-filter-container {
    .fa {
      left: 1px;
      position: absolute;
      top: 15px;
    }
  }
}

.form-errors {
  color: $stateErrorTextColor;
}

.vehicle-type-errors {
  top: 10px;
  position: relative;
}

.edit-profile-country-code {
  .p-dropdown-width {
    .p-dropdown {
      width: 140px !important;
    }
  }
}

.p-fieldset-content {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.autocomplete {

  &.quarter-ui {
    input {
      &[type='text'] {
        width: 88%;
      }
    }
  }

  input {
    &[type='text'] {
      height: $inputTextHeight;
      margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
      padding: 0;
      width: 94%;
    }
  }
}

.p-autocomplete {
  width: 100%;

  .p-inputtext {
    border: 1px solid #008000;
  }
}

.edit-profile-popup {
  height: auto;
  width: 50%;
}

.popup {
  height: auto;
  width: 50%;
}

.add-hotel-popup {
  height: 500px;
  width: 50%;
}

.add-edit-route-popup {
  max-height: 500px;
  width: 65%;

  .p-dialog-content {
    max-height: 450px;
    overflow-y: auto;
    padding: 0;
  }
}


.form-mrg {
  padding-right: 5%;

  .p-calendar {
    .p-button {
      &.p-button {
        height: 31px;
        margin-top: 8px;
      }
    }
  }

  .fieldset-style-class {
    width: 100%;
  }
}

.dispatcher-role-table {
  border: 1px solid $mainContentBgColor;
  border-collapse: collapse;
  margin: 7px 0;
  width: 96%;

  .table-header {
    background-color: $gridHeaderBgColor;
    border: 1px solid $mainContentBgColor;
    border-bottom: 10px solid $mainContentBgColor;
    color: $tableHeaderText;
    height: 30px;
    margin-bottom: 10px;
    padding: 10px;
    text-align: left;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .table-rows {
    background-color: $inputBgColor;
    height: 60px;
    padding: 10px;
  }

  .col-header {
    padding: 10px;
  }

  th {
    &.col-header-center {
      border-radius: 5px;
      padding: 10px;
      text-align: center;
    }
  }

  td {
    &.col-header-center {
      padding: 10px;
      text-align: center;
    }
  }
}

.quarter-ui {
  .p-dropdown {
    min-width: 92%;
  }
}

.bookings-autocomplete {
  input {
    &[type='text'] {
      width: 100%;
    }
  }

  .p-button {
    &.p-button {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      position: absolute;
      right: 0;
      top: 8px;
    }
  }
}

.mobile-number {
  padding: 0 .5em;
}

.form-without-border {
  background-color: #FFF;

  form {
    width: 100%;

    .form-field {

      input {
        margin: 20px 0;
      }

      .p-dropdown {
        background: $contentBgColor;
        border: 0;
        border-bottom: 1px solid #ECEDF3;
        border-radius: 0;
        height: 32px;
        margin: 18px 0;
        min-width: 0;
        width: 60px;

        &:not(.p-state-disabled) {

          &:hover {
            background-color: $contentBgColor;
            border-color: #ECEDF3;
          }
        }
      }
    }
  }
}

.save-btn {
  &.p-button {
    background-color: #06A0FF;
    border: 1px solid #06A0FF;
    border-radius: 5px;
    color: #FFF;
    font-weight: bold;
    // width: 140px;

    &:enabled {
      &:hover {
        background-color: #06A0FF;
        border: 1px solid #06A0FF;
        color: #FFF;
      }
    }
  }
}

.cancel-btn {
  &.p-button {
    background-color: #FFF;
    border: solid 1px #212736;
    border-radius: 5px;
    color: #000;
    font-weight: 700;
    width: 140px;

    &:enabled {
      &:hover {
        background-color: #FFF;
        border: solid 1px #000;
        color: #000;
      }
    }
  }
}

.signin-save-btn {
  &.p-button {
    background-color: #06A0FF;
    border: 0;
    border-radius: 5px;
    color: #FFF;
    font-weight: bold;
    width: 140px;
    height: 33px;

    &:enabled {
      &:hover {
        background-color: #06A0FF;
        border: 0;
        color: #FFF;
      }
    }
  }
}

.cancel-back-btn {
  &.p-button {
    background-color: #FFF;
    border: 1px solid #000;
    border-radius: 5px;
    color: #000;
    font-weight: bold;
    width: 140px;
    height: 33px;

    &:enabled {
      &:hover {
        background-color: #FFF;
        border: 1px solid #000;
        color: #000;
      }
    }
  }
}

.profile-image {
  margin: auto;
  overflow: hidden;
  padding-right: 10px;
}

.header-btn {
  margin: auto 0;
}

.p-checkbox-label {
  // sass-lint:disable no-important
  margin: 0 0 0 8px !important;
}
