.search-and-export-data-panel {
  input {
    &.grid-search-bar {
      border: 1px solid $contentBorderColor;
      border-radius: 5px;
      font-size: 15px;
      height: 33px;
      padding: 3px;
      padding-left: 10px;
      padding-right: 28px;
      width: 250px;

      &.top {
        margin-bottom: 15px;
      }

      &.bottom {
        margin-top: 15px;
      }
    }
  }

  label {
    &.input-icon {
      font-size: 15px;
      position: relative;
      right: 27px;
    }
  }

  .export-button {
    float: right;
    margin-right: 5px;

    &.top {
      margin-bottom: 15px;
    }

    &.bottom {
      margin-top: 15px;
    }

    .header-action-btn {
      background-color: $white;
      border: 1px solid $defaultThemeColor;
      border-radius: 5px;
      color: $defaultThemeColor;
      cursor: pointer;
      float: right;
      font-size: 14px;
      margin: 0 8px;
      padding: 7px 6px;
      vertical-align: middle;

      &:hover {
        border: 1px solid $defaultThemeColor;
        color: $defaultThemeColor;
      }
    }
  }
}

app-mi-grid-v2 {
  .col-12 {
    padding: 0;
  }
}

.mi-grid-component {
  background: $white;
  border-radius: 10px;
  height: 100%;
  margin: 0 auto;
  margin-top: 10px;
  overflow: hidden;
  padding: 20px 0;
  width: 100%;

  .p-table {
    table {
      width: calc(100% + 2px);
    }

    .p-datatable-tbody {
      > tr {
        background: #FFF;
        border-bottom: 2px solid $contentBorderColor;
        color: #333;

        &:focus {
          outline: 0;
        }

        &.p-state-highlight {
          background-color: $white;
          color: inherit;
        }

        &:not(.p-state-highlight) {
          &:hover {
            background-color: #FFF;
            border-bottom: 2px solid #06A0FF;
            color: inherit;
          }
        }

        &.even-row {
          background-color: #FFF;

          &:not(.p-state-highlight) {
            &:hover {
              background-color: #FFF;
              border-bottom: 2px solid #06A0FF;
              color: inherit;
            }
          }
        }

        > td {
          border: 0;
          cursor: auto;
          font-size: 15px;
          min-height: 30px;
          padding: 10px;
          padding-left: 15px;

          div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &:last-child {
            border: 0;
          }

          .p-button {
            // &.p-state-default {
              margin: 3px;

              &.update-status {
                background: transparent;
                border: 0;
                color: $defaultThemeColor;

                .p-button-text {
                  padding: 5px;
                }
              }
            // }
          }
        }

        &:nth-child(even) {
          background: #FFF;

          &.p-state-highlight {
            background: #FFF;
            color: inherit;
          }
        }

        &:nth-child(odd) {
          &.p-state-highlight {
            background: #FFF;
            color: inherit;
          }
        }
      }
    }

    .p-table-thead {
      > tr {
        > th {
          background: transparent;
          border-bottom: 1px solid #C0BEBE;
          border-left: 0;
          border-right: 0;
          border-top: 0;
          clear: both;
          font-size: 15px;
          font-weight: 500;
          overflow: hidden;
          padding: 10px;
          padding-left: 0;
          text-align: left;
          text-transform: uppercase;

          &:focus {
            outline: 0;
          }
        }
      }
    }

    .p-paginator-bottom {
      background-color: $white;
      border: 0;
      padding: 10px;

      a {
        border-radius: 50%;
        height: auto;
        line-height: 2em;
        margin: 0 5px;
        min-height: 2.5em;
        min-width: 2.5em;
        padding: 4px;
      }
    }

    .p-sortable-column {
      &.p-state-highlight {
        background-color: $white;
        color: inherit;

        .p-sortable-column-icon {
          color: inherit;
        }
      }

      &:not(.p-state-highlight) {
        &:hover {
          background-color: $white;
          color: inherit;

          .p-sortable-column-icon {
            color: inherit;
          }
        }
      }
    }
  }

  p-table {
    &.detail-screen-available {
      .p-table {
        .p-table-tbody {
          > {
            tr {
              > td {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .p-table-resizable {
    > .p-table-wrapper {
      overflow: inherit;
    }
  }

  .grid-action-buttons {
    .grid-action {
      display: flex;
      justify-content: space-around;

      .render-html {
        cursor: pointer;
        margin-right: 10px;
        min-width: 20px;
      }
    }
  }
}

.grid-btn-div {
  border-radius: 8px;
  color: #FFF;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  width: 190px;

  &.new-request-btn {
    background-color: #F00;
  }

  &.attended-btn {
    background-color: #808080;
  }

  &.closed-btn {
    background-color: #080;
  }
}

.action-colunm-header {
  padding-left: 15px;
  width: 220px;
}

.action-header {
  text-align: center;
}

.grid-action-buttons {
  .grid-action {
    display: flex;
    justify-content: space-around;

    .render-html {
      cursor: pointer;
      min-width: 20px;
    }
  }
}

.mi-xls-pdf-div {
  display: inline-block;
}

// .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
//   color: #333;
// }

// .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
//   color: #333;
// }

// .p-datatable .p-sortable-column.p-highlight:hover {
//   background: none;
//   color: #333;
// }

// .p-datatable .p-sortable-column.p-highlight {
//   background: none;
//   color: #333;
// }

.p-datatable {
  .p-sortable-column {
    &:focus {
      box-shadow: none;
    }

    &.p-highlight {
      background: none;
      color: #333;

      .p-sortable-column-icon {
        color: #333;
      }

      &:hover {
        background: none;
        color: #333;

        .p-sortable-column-icon {
          color: #333;
        }
      }
    }
  }

  .p-datatable-tbody > tr > td {
    border-bottom: 2px solid #C8C8C8;
    border-left: 0;
    border-right: 0;
    border-top: 0;
  }

  .p-datatable-thead > tr > th {
    background: none;
    border-bottom: 1px solid #C8C8C8;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    font-weight: 501;
  }
}

p-table {
  .p-datatable {
    .p-sortable-column {
      .p-sortable-column-icon {
        color: #32455f;
        margin-left: 0;
        display: inline-grid;
        height: 11px;
        display: none;
      }
    }

    sortamountdownicon {
      &:before {
        content: '\25BE';
      }
    }

    sortamountupalticon {
      &:before {
        content: '\25B4';
      }
    }

    sortalticon {
      display: inline-grid;
      bottom: 3px;
      position: relative;
      &:before {
        content: '\25B4';
        height: 7px;
      }
      &::after {
        content: '\25BE';
        height: 7px;

      }
    }
  }
}
