body {
  color: $BodyTextColor;
  font-family: $fontFamily;
  font-size: $fontSize;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  .p-widget {
    font-family: $fontFamily;
  }

  .p-button {
    &:enabled {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .p-dialog {
    background: #FFF;

    &.ui-corner-all {
      border: 0;
    }

    .p-dialog-content {
      border: 0;
      color: #333;
      min-height: min-content;
      overflow: visible;
    }

    .p-dialog-footer {
      border-top: 0;
      top: 0;
    }
  }

  .loader-page-center-overlay {
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 500000;
  }

  .loader-overlay-panel {
    bottom: 0;
    height: 78px;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 70px;
    z-index: 500000;
  }

  p-dropdown {
    .ng-dirty {
      .ng-invalid > .p-dropdown {
        border: 0;
        border-bottom: 1px solid #ECEDF3;
      }
    }
  }

  p-calendar {
    &.ng-dirty {
      &.ng-invalid > .p-calendar > .p-inputtext {
        border: 0;
        border-bottom: 1px solid #ECEDF3;
      }
    }
  }

  p-autocomplete {
    &.ng-dirty {
      &.ng-invalid > .p-autocomplete > .p-inputtext {
        border: 0;
        border-bottom: 1px solid #ECEDF3;
      }
    }
  }

  .p-chkbox,
  .p-radiobutton {
    .p-chkbox-box,
    .p-radiobutton-box {
      &.p-state-focus {
        box-shadow: none;
      }

      &.p-state-default {
        border-color: #6E6C6E;
      }

      &:not(.p-state-disabled) {
        &:not(.p-menuitem-link-active) {
          &:hover {
            border-color: #06A0FF;
          }
        }
      }
    }
  }

  .p-editor-container {
    .ql-snow {
      &.ql-toolbar {
        button {
          &.ql-active {
            .ql-stroke {
              stroke: #06C;
            }
          }
        }
      }
    }
  }
}

.sign-in-icon {
  height: 20px;
  margin: 15px 0 0 20px;
  width: 25px;
}

.password-visible-icon {
  color: #9E9A9A;
  cursor: pointer;
  float: right;
  font-size: 16px;
  margin-right: 23px;
  margin-top: -32px;
  position: relative;
}

.text-center {
  text-align: center;
}

.mrg-top-10 {
  margin-top: 10px;
}

.image-code {
  background: url('/assets/images/temp/sprite.png') 0 0;
}

.image-rating-width {
  height: 25px;
  width: 25px;
}

.p-rating {
  a {
    // sass-lint:disable no-important
    color: $defaultThemeColor !important;
  }
}

.rating1 {
  background-position: -69px -38px;
}

.rating2 {
  background-position: -119px -38px;
}

.rating3 {
  background-position: -168px -38px;
}

.rating4 {
  background-position: -219px -38px;
}

.rating5 {
  background-position: -269px -38px;
}

.track-driver {
  cursor: pointer;
  float: right;

  &:hover {
    color: $defaultThemeColor;
  }
}

input {
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

a {
  color: $linkColor;

  &:link,
  &:active,
  &:focus {
    color: $linkColor;
    outline: none;
    text-decoration: none;
  }

  &:hover {
    color: $stateFocusBgColor;
    outline: none;
    text-decoration: none;
  }
}

.main-panel {
  background-color: $mainContentBgColor;
  display: flex;
  margin-top: 3px;
}

.page-header {
  background-color: $pageHeaderBgColor;
}

.page-buttons {
  button {
    float: right;
    margin: 0 0 0 8px;
  }

  .p-button-icon-only {
    .p-button-icon-left {
      font-size: 23px;
    }
  }
}

.ql-editor {
  background: $white;
}

.tab-menu-dashboard {
  p-tabmenu {
    .p-tabmenu {
      &.p-widget {
        &.p-widget-content {
          background-color: $white;
          border: 0 none;
          overflow: hidden;
          width: 100%;
          z-index: 99;
        }
      }
    }
  }
}

p-tabmenu {
  .p-widget-content {
    border: 0 none;
  }
}

ul {
  &.p-widget-header {
    border: 0 none;

    .p-tabmenuitem {
      a {
        color: $inputTextColor;
      }

      &.p-menuitem-link-active {
        a {
          color: $gridHeaderTextColor;
        }
      }
    }
  }
}

.p-tabmenu {
  .p-tabmenu-nav {
    background: #FFF;
    border: 0;
    font-size: 15px;

    .p-tabmenuitem {
      &:focus {
        .p-menuitem-link {
          box-shadow: none !important;
          outline: none !important;
        }
      }
      &.p-highlight {

        .p-menuitem-link {
          background: none;
          border-bottom: 3px solid #3B7BFF;
          color: #007AD9;
          box-shadow: none !important;
          outline: none !important;
        }
      }

      .p-menuitem-link {
        background: transparent;
        border: 0;
      }

      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            .p-menuitem-link {
              background: #FFF;
              border-color: #FFF;
              color: #333;
            }
          }
        }
      }
    }

    li {
      &.p-tabmenuitem {
        background-color: transparent;

        &.p-menuitem-link-active {
          background-color: transparent;
          border: 0;
          border-bottom: 3px solid $defaultThemeColor;
          font-weight: 500;
          position: relative;

          .p-menuitem-link {

            &:not(.p-disabled) {
              &:focus {
                box-shadow: none;
                outline: 0 none;
                outline-offset: 0;
              }
            }

            &.ui-corner-all {
              .p-menuitem-text {
                color: $defaultThemeColor;
              }

              .p-menuitem-icon {
                color: $defaultThemeColor;
              }
            }
          }
        }

        &:not(.p-menuitem-link-active) {
          background-color: transparent;
          border: 0;

          &:hover {
            background-color: transparent;
            border: 0;

            // a {
            //   color: $defaultThemeColor;
            // }
          }

          &:not(.p-state-disabled) {
            &:hover {
              background-color: transparent;
              border: 0;
            }
          }

          a {
            color: #000;
          }
        }

        .p-menuitem-link {
          &.ui-corner-all {
            .p-menuitem-text {
              color: #000;
            }

            .p-menuitem-icon {
              color: #000;
            }
          }
        }
      }
    }
  }
}

.panel-div {
  background-color: $panelDivBgColor;
  border-radius: 10px;
  margin-top: 10px;
  padding: 8px;
}

.panel-div-top-info {
  padding: 0 0 8px;

  .left-info {
    text-align: left;
  }

  .right-info {
    text-align: right;
  }
}

h1 {
  &.page-title {
    font-size: 18px;
    margin: 0;

    .page-count {
      color: #7A7D87;
      font-weight: 500;
      opacity: .9;
    }
  }
}

.menu-header {
  overflow: hidden;
  position: relative;
}

.bread-crumbs {
  float: right;
  padding: 14px 5px;
}

.bread-item {
  display: inline-block;
  list-style-type: none;
}

.p-panel {
  .p-widget-header {
    font-size: $headerFontSize;
    font-weight: bold;
  }

  .p-panel-titlebar {
    padding: 8px 16px;
  }

  .p-panel-content {
    background-color: #FFF;
    border: 0;
    border-radius: 5px;
    color: #333;
    padding: 50px 30px;
  }
}

.p-menu {
  &.p-menu-overlay {
    // sass-lint:disable no-important
    left: 60px !important;
    top: 60px !important;
  }
}

.referral-form-label {
  padding-top: 20px;
}

.p-button {
    background: $taxipulseThemeColor;
    border: 1px solid $taxipulseThemeColor;
    color: $buttonDefaultTextColor;
    font-weight: 500;
  .p-button-icon-only {
    // sass-lint:disable no-important
    height: 32px !important;
    top: 7px !important;
  }

  // &.p-state-default {
    // background: $taxipulseThemeColor;
    // border: 1px solid $taxipulseThemeColor;
    // color: $buttonDefaultTextColor;
    // font-weight: 500;
    

    &:hover {
      background: $themeButtonHoverColor;
      border: 1px solid $themeButtonHoverColor;
    }

    &.margin-button {
      margin-right: 16px;
    }

    &.p-button-text-only {
      .p-button-text {
        padding: 8px 16px;
      }
    }
  // }

  &.negative-button {
    background-color: $negativeButtonColor;
    border: 1px solid $negativeButtonColor;
    font-weight: 500;

    &:hover {
      background-color: $negativeButtonHoverColor;
      border: 1px solid $negativeButtonHoverColor;
    }
  }

  &.p-button-danger {
    // &:enabled {
    //   &:hover {
    //     background-color: $stateFocusBgColor;
    //   }
    // }

    &:focus {
      background-color: $stateFocusBgColor;
    }
  }
}

.p-button-text-only {
  .p-button-text {
    padding: 8px 16px;
  }
}

.p-button-text-icon-left {
  font-size: 18px;

  .p-button-text {
    font-size: 13px;
    padding: 8px 10px 8px 36px;
  }
}

.p-inputtext {
    background: $inputFieldsBgColor;
    border: 1px solid $inputFieldBorderColor;
    color: $contentTextColor;
    padding: 12px 16px;
  // &.p-state-default {
  //   background: $inputFieldsBgColor;
  //   border: 1px solid $inputFieldBorderColor;
  //   color: $contentTextColor;
  //   padding: 12px 16px;
  // }

  &:enabled {
    &:hover {
      border: 0;
      border-bottom: 1px solid $inputFieldBorderColor;
      box-shadow: none;

      &:not(.p-state-error) {
        // sass-lint:disable no-important
        border-color: #06A0FF !important;
      }
    }

    &:focus {
      border: 0;
      border-bottom: 1px solid $inputFieldBorderColor;
      box-shadow: none;
    }
  }
}

.p-growl-item-container {
  &.p-state-highlight {
    &.p-growl-message-success {
      background-color: $growlMessageSuccessColor;
      border-color: $growlMessageSuccessColor;
    }

    &.p-growl-message-error {
      background-color: $growlMessageErrorColor;
      border-color: $growlMessageErrorColor;
    }
  }
}

.partner-box {
  background: $white;
  border: 1px solid $inputFieldBorderColor;
  color: $headerTextColor;
  margin-bottom: 20px;
}

.out-partner-info {
  cursor: pointer;
  text-align: right;
}

.lang-right-to-left {
  .form-label,
  a,
  .p-panel-titlebar,
  p {
    direction: rtl;
  }

  input {
    &[type='text'] {
      direction: rtl;
    }
  }

  .p-panelmenu {
    .p-menuitem-icon {
      &.fa {
        margin-left: .5em;
      }
    }
  }
}

.assign {
  .p-inputtext {
    // &.p-state-default {
      height: 32px;
      padding: 0 10px;
      width: 100%;
    // }
  }

  .p-autocomplete {
    width: 100%;

    .p-autocomplete-dropdown {
      background-color: $bodyBgColor;
      border: 0 none;
      color: $defaultThemeColor;
      height: 29px;
      position: absolute;
      right: 1px;
      top: 9px;
      vertical-align: top;
      width: 22px;
    }
  }

  &.search-driver {
    .p-autocomplete-dropdown {
      background-color: $bodyBgColor;
      border: 0 none;
      color: $defaultThemeColor;
      height: 29px;
      position: absolute;
      right: 1px;
      top: 1px;
      vertical-align: top;
      width: 22px;
    }
  }
}

.web-link {
  color: $stateDefaultTextColor;
}

.caps {
  text-transform: uppercase;
}

.map {
  height: 100%;
  width: 100%;
}

.fc {
  &.fc-theme-standard {
    .fc-view-harness {
      .fc-scrollgrid {
        // sass-lint:disable no-important
        width: 500px !important;
      }
    }
  }

  .fc-toolbar-title {
    position: relative;
    right: 10px;
  }
}

// .fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
//   width: 500px !important;
// }

.closed {
  .sidebar {
    width: 80px;

    .company-logo {
      padding: 31px;

      img {
        display: none;
      }
    }

    .p-menuitem-text {
      display: none;
    }
  }

  .header-panel {
    .company-logo {
      width: 81px;

      img {
        display: none;
      }
    }

    .p-menuitem-text {
      display: inline-block;
    }
  }

  .main-panel {
    .right-panel {
      width: calc(100% - 60px);
    }
  }
}

.mi-pie-chart {
  display: inline-block;
  height: auto;
  width: 250px;
}

.mi-bar-chart {
  display: inline-block;
  height: 271px;
  width: 60%;
}

.flexbox {
  flex: 1;
  height: 233px;
}

.error {
  color: $defaultThemeColor;
  font-size: 16px;
}

.top-padding {
  padding-top: 3%;
}

.document {
  cursor: pointer;
}

.loader-hidden {
  visibility: hidden;
}

.loader-page-center-overlay {
  background-color: $loaderBgColor;
  bottom: 0;
  height: 70px;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 88px;
  z-index: 500000;
}

.info-label {
  color: #3D4246;
  display: inline-block;
  font-weight: bold;
}

.info-data {
  display: inline-block;
}

.add-edit-panel {
  padding: 30px 20px 20px;
}

.p-button-icon-only {
  .p-button-text {
    margin-top: -2px;
  }
}

.p-autocomplete {
  .p-autocomplete-panel {
    margin-top: 10px;
    min-height: 40px;
  }

  .p-autocomplete-dropdown {
    height: 30px;
    position: absolute;
    right: 0;
    top: 7px;
    vertical-align: inherit;
  }
}

.user-pic {
  border: 1px solid #000;
  border-radius: 8px;
  width: 100%;
}

.driver-pic {
  background: #F5F6FA;
  border: 1px solid #000;
  border-radius: 4px;
  min-height: 120px;
  min-width: 120px;
  width: 120px;

  &.pic-size {
    min-height: 135px;
    width: 135px;
  }
}

p-calendar {
  .p-calendar {
    .p-datepicker {
      min-width: 315px;
    }

    &.p-calendar-w-btn {
      input {
        &[type='text'] {
          border-bottom: 1px solid #ECEDF3;

          &:hover {
            border-bottom: 1px solid #06A0FF;
          }

          &:focus {
            border-color: $inputBorderColor;
          }
        }
      }

      .p-button {
        border: 0;
        border-bottom: 1px solid #ECEDF3;
        height: 34px;
        top: -4px;
      }
    }
  }

  &.short-input {
    .p-calendar {
      input {
        &[type='text'] {
          width: 95%;
        }
      }
    }

    .p-button {
      &.p-state-default {
        right: 4%;
      }
    }
  }

  .p-button {
    // &.p-state-default {
      background-color: $inputFieldsBgColor;
      border: 1px solid $inputFieldBorderColor;
      border-left: 0 none;
      color: $calendarIconColor;
      height: 30px;
      margin: 8px 1px;
      position: absolute;
      right: 0;
    // }
  }

  input {
    width: 100%;
  }

  .p-timepicker > div {
    margin-left: 15px;
  }
}

.ui-spinner {
  .ui-spinner-up {
    font-size: 12px;
    height: 14px;
    margin: 8px 0 0;
  }

  .ui-spinner-down {
    font-size: 12px;
    height: 14px;
    margin: 0;
    top: 24px;
  }
}

.inner-container {
  padding: 0 15px;
}

.gridparent {
  margin: 0 6px;
  padding: 0;
}

.img-doc {
  max-width: 60px;
}

.active-box {
  background: $white;
  box-shadow: 0 3px 5px -2px #000;
  color: $activeBoxTextColor;
  margin-right: 20px;
  text-align: left;

  .special-count {
    float: right;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

form {
  width: 100%;

  .checkout {
    background: #FFF;
    border: 2px solid #EEE;
    border-radius: 8px;
    color: #525252;
    font-family: $fontFamily;
    font-size: 1.1rem;
    margin: 2rem auto;
    max-width: 500px;
    padding: 1rem 2rem;
    text-align: center;

    button {
      background: #FF7F50;
      border: 0;
      color: #FFF;
      margin-top: 1rem;
      padding: .5rem 1rem;

      &:active {
        background: rgb(165, 76, 43);
      }
    }
  }
}

.form-buttons {
  &.corporate-button-bar {
    margin-top: 60px;
  }

  button {
    &.destination-add-full-btn {
      margin: 0;
      width: 100%;
    }
  }
}

.reset-btn {
  background-color: $white;
  border: 1px solid $taxipulseThemeColor;
  border-radius: 4px;
  color: $taxipulseThemeColor;
  cursor: pointer;
  float: left;
  margin-top: 7px;
  padding: 6px 10px;
}

p-dropdown {
  .p-dropdown-items {
    .p-state-highlight {
      background: $dropdownBgColor;
      border-color: $dropdownBorderColor;
      color: $white;
    }
  }

  .p-dropdown {
    border-color: $inputFieldBorderColor;
    width: 100%;

    &:not(.p-state-disabled) {
      &:active {
        border-color: $inputFieldBorderColor;
      }

      &:hover {
        border-color: $inputFieldBorderColor;
      }
    }

    .p-dropdown-trigger {
      .fa {
        color: $taxipulseThemeColor;
      }
    }
  }

  .p-inputtext {
    &:focus {
      border-color: $inputFieldBorderColor;
      box-shadow: none;
    }
  }
}

.form-table-label {
  background: $gridHeaderBgColor;
  margin-bottom: 5px;
  padding: 5px;
}

.form-table-value {
  margin-bottom: 5px;
  padding: 5px;
}

.p-chkbox,
.p-radiobutton {
  .p-chkbox-box,
  .p-radiobutton-box {
    // &.p-state-default {
      background: $checkboxBackgroundColor;
      border-color: $checkboxActiveBorderColor;
      color: $checkboxCheckColor;
    // }

    &:not(.p-state-disabled) {
      &:not(.p-menuitem-link-active) {
        &:hover {
          background-color: $checkboxBackgroundColor;
          border-color: $checkboxActiveBorderColor;
        }
      }
    }

    &.p-menuitem-link-active {
      border-color: $checkboxActiveBorderColor;
    }
  }
}

.form-field {
  .asterisk {
    color: $growlMessageErrorColor;
  }

  .employee-input {
    input {
      &[type='text'] {
        width: 100%;
      }
    }
  }

  .p-dropdown-panel {
    input {
      &[type='text'] {
        border-bottom: 1px solid #ECEDF3;
        width: 100%;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .p-dropdown-filter-container {
      width: 100%;

      .fa {
        position: absolute;
        right: 21px;
        top: 21px;
      }
    }

    .p-dropdown-item {
      &:not(.p-state-highlight) {
        &:hover {
          background-color: $secondaryThemeColor;
          border-color: $secondaryThemeColor;
        }
      }
    }
  }
}

.delete-bin {
  &.fa {
    cursor: pointer;
    float: right;
    font-size: 16px;

    &:hover {
      color: $defaultThemeColor;
    }
  }
}

.document-upload {
  li {
    list-style-type: none;
  }
}

.document-container {
  width: 100%;

  .p-fieldset .p-fieldset-legend {
    border: none;
  }
}

.p-datepicker {
  .p-datepicker-calendar {
    td {
      padding: 0;

      &:not(.p-state-disabled) {
        a {
          &.p-state-highlight {
            background-color: $secondaryThemeColor;
            border-color: $secondaryThemeColor;
            color: $white;
          }

          &.p-menuitem-link-active {
            background-color: $defaultThemeColor;
            border-color: $defaultThemeColor;
            color: $white;
          }

          &:hover {
            background-color: $defaultThemeColor;
            border-color: $defaultThemeColor;
          }
        }
      }
    }
  }
}

.scroll-panel {
  overflow: scroll;
  padding: 0 0 16px;
}

.box-bottom-border {
  border-bottom: 1px solid $sidebarIconColor;
}

.step-tab {
  .p-steps-item {
    &.p-state-highlight {
      background-color: $secondaryThemeColor;

      a {
        color: $white;

        &:focus {
          color: $white;
        }
      }

      &:hover {
        a {
          color: $white;
        }
      }
    }

    &.p-state-disabled {
      a {
        color: $secondaryThemeColor;
      }
    }
  }
}

.p-calendar {
  width: 465px;
}

p-multiselect {
  .p-multiselect {
    border: 0;
    border-bottom: 1px solid #ECEDF3;
    border-radius: 0;
    margin: 9px 0;
    width: 300px;

    &:not(.p-state-disabled) {
      &:hover {
        border-color: #06A0FF;
      }
    }

    .p-multiselect-trigger {
      border: 0 none;
    }

    .p-multiselect-label {
      margin: 0;
      padding: .429em;
      padding-right: 17px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

p-selectbutton {
  .p-selectbutton {
    .p-button {
      &:not(.p-state-disabled) {
        &:not(.p-menuitem-link-active) {
          &:hover {
            background-color: $secondaryThemeColor;
            border-color: $secondaryThemeColor;
          }
        }
      }
    }
  }
}

p-chips {
  .p-chips {
    .p-inputtext {
      &:hover {
        border-color: $inputFieldBorderColor;
      }

      .p-chips-token {
        &.p-state-highlight {
          background-color: $defaultThemeColor;
          border-color: $defaultThemeColor;
        }
      }

      .p-chips-input-token {
        input {
          &[type='text'] {
            visibility: hidden;
          }
        }
      }
    }
  }
}

.right-align {
  padding-right: 3%;
  text-align: right;
}

.same-height-div {
  display: flex;

  .beside-div-same-height {
    background: $white;
    flex: 1;
    margin: 0 .5em .5em;
    padding: 0;
  }
}

p-dialog {
  .add-popup {
    .p-dialog-titlebar {
      background: #FFF;
      color: #212736;
      font-family: Roboto;
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      letter-spacing: normal;
      line-height: normal;
      position: relative;
      text-align: center;

      .p-dialog-titlebar-icon {
        color: #000;
      }
    }

    &.slot-dialog {
      .p-dialog-titlebar {
        padding: 10px 0;

        .p-dialog-title {
          color: #77838F;
          font-family: Roboto;
          font-size: 18px;
          font-stretch: normal;
          font-style: normal;
          font-weight: 500;
          letter-spacing: normal;
          line-height: normal;
        }
      }

      .p-dialog-content {
        .driver {
          background-color: #EFEFEF;
          padding: 15px;

          .driver-name {
            color: #212736;
            font-family: Roboto;
            font-size: 15px;
            font-stretch: normal;
            font-style: normal;
            font-weight: 500;
            letter-spacing: normal;
            line-height: normal;
          }

          .global-time {
            color: #707070;
            font-family: Roboto;
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            font-weight: normal;
            letter-spacing: normal;
            line-height: normal;
            margin: 8px 0 0;
          }
        }

        .trip {
          color: #212121;
          font-family: Roboto;
          font-size: 15px;
          font-stretch: normal;
          font-style: normal;
          font-weight: bold;
          letter-spacing: normal;
          line-height: normal;
        }

        .divider {
          background-color: #CCC;
          height: 17px;
          left: 20px;
          position: absolute;
          width: 2px;
        }

        .location {
          color: #121212;
          font-family: Roboto;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          font-weight: normal;
          letter-spacing: normal;
          line-height: normal;
          padding: 7px 0;
        }

        .slots {
          .groups {
            padding: 10px 0;

            .slot-type {
              border-radius: 4.5px;
              height: 9px;
              width: 9px;

              &.available {
                background-color: #D2EAD3;
                border: solid 1.1px #A5D6A7;
              }

              &.busy {
                background-color: #F7CCCC;
                border: solid 1.1px #EF9A9A;
              }
            }

            .grp-name {
              color: #212121;
              font-family: Roboto;
              font-size: 15px;
              font-stretch: normal;
              font-style: normal;
              font-weight: bold;
              letter-spacing: normal;
              line-height: normal;
              padding-left: 15px;
            }
          }
        }
      }
    }
  }

  .p-dialog {
    &.ui-corner-all {
      border: 0;
    }

    .p-dialog-titlebar {
      background: $secondaryThemeColor;
      border: 0;
      color: $white;
      font-size: 16px;
      padding: 1em 1.1em;
      position: relative;

      .p-dialog-titlebar-close {
        border: 0;
        color: $white;

        &:hover {
          background-color: $secondaryThemeColor;
          border: 0;
          color: $white;
        }
      }
    }

    .p-dialog-footer {
      overflow: hidden;
      padding: .8em;
    }
  }
}

.add-vehicle {
  .p-dialog {
    &.ui-corner-all {
      border: 0;
    }

    .p-dialog-titlebar {
      background: $white;
      border: 0;
      color: #464444;
      font-size: 18px;
      font-weight: 400px;
      padding: 1em 1.1em;
      position: relative;
      text-align: center;

      .p-dialog-titlebar-close {
        border: 0;
        color: #3B7BFB;

        &:hover {
          background-color: $secondaryThemeColor;
          border: 0;
          color: $white;
        }
      }
    }

    .p-dialog-content {
      min-height: 240px;
    }

    .vehicle-list-wrapper {
      max-height: 243px;
    }

    .vehicle-listrow {
      border: 1px solid #DDD;
      border-radius: 5px;
      display: inline-block;
      float: left;
      margin-left: 10px;
      margin-top: 4px;
      padding: 5px;
    }

    .p-dialog-footer {
      overflow: hidden;
      padding: .8em;
    }

    .vehicle-text-color {
      color: #3B7BFB;
      font-size: 12px;
    }

    .vehicle-footer {
      bottom: 0;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
}

.remove-vehicle {
  .p-dialog {
    &.ui-corner-all {
      border: 0;
    }

    .p-dialog-titlebar {
      background: $white;
      border: 0;
      color: #464444;
      font-size: 18px;
      font-weight: 400px;
      padding: 1em 1.1em;
      position: relative;
      text-align: center;

      .p-dialog-titlebar-close {
        border: 0;
        color: #3B7BFB;

        &:hover {
          background-color: $secondaryThemeColor;
          border: 0;
          color: $white;
        }
      }
    }

    .p-dialog-content {
      height: 250px;
    }

    .p-dialog-footer {
      overflow: hidden;
      padding: .8em;
    }

    .remove-vehicle-parent &:nth-child(odd) {
      background-color: #EFEFEF;
    }
  }
}

.access-checkbox-div {
  display: inline-block;
  padding: 5px;
}

.access-editbtn-div {
  display: inline-block;
  padding: 5px;
}

.department-autocomplete {
  margin-top: 4%;

  .p-inputtext {
    input {
      &[type='text'] {
        border: 0 none;
      }
    }
  }

  // .p-state-default {
    padding: 10px;
  // }

  .p-autocomplete-panel {
    width: 96%;
  }

  ul {
    width: 96%;

    li {
      font-size: 14px;
    }
  }
}

.faq-form {
  margin-bottom: 30px;
}

.faq-panel-marin {
  margin-bottom: 2%;

  .content {
    padding-bottom: 2%;
    text-align: justify;
  }

  .footer {
    text-align: right;
  }

  p-header {
    font-size: 14px;
    padding: 0;
  }

  &.p-panel {
    padding: 0;

    .p-panel-titlebar {
      background: $secondaryThemeColor;
      border: 0;
      color: $sidebarIconColor;
    }

    .p-panel-titlebar-icon {
      color: $secondaryThemeColor;
      font-size: 13px;
      margin-top: 5px;

      span {
        margin-top: 3px;
      }
    }

    .p-panel-content {
      padding: 16px;
    }

    .cursor-pointer {
      cursor: pointer;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.visible-hidden {
  visibility: hidden;
}

.h-46 {
  height: 46px;
}

.map-container {
  height: 450px;
  padding-left: 20px;
}

.tracking-map-container {
  height: 550px;
  padding-left: 20px;
}

.trip-address-type {
  background-color: #F5F6FA;
  height: 50px;
  margin: 0 !important;
}

.bottom-7px {
  bottom: 7px;
}

.width-140px {
  width: 140px;
}

.bottom-16px {
  bottom: 16px;
}

.term-conditions {
  padding: 45px;

  h3 {
    color: $sideBarHoverBgColor;
    font-size: 17px;
    margin: 0;
  }
}

.none-list-type {
  list-style-type: none;
}

.ql-align-justify {
  color: $secondaryThemeColor;
  font-size: 15px;
  margin: 10px 0 45px;
  text-align: justify;
}

.driverassigndropdown {
  .p-autocomplete {
    width: 100%;

    input {
      &[type='text'] {
        border: 1px solid #E1E3E7;
        font-family: $fontFamily;
        font-size: 14px;
        height: 32px;
        margin: 7px 0;
        padding: 0;
        width: 94%;
      }
    }

    .p-autocomplete-dropdown {
      border: 0 none;
      position: absolute;
      right: 2px;
      top: 8px;
      vertical-align: top;
    }
  }

  .driver-name {
    display: inline-block;
    margin: 10px 10px 0 0;
    text-align: left;
    width: 23%;
  }

  .driver-ref-no {
    display: inline-block;
    margin: 10px 10px 0 0;
    text-align: center;
    width: 35%;
  }
}

.trip-details {
  .pickup-circle {
    color: $mapPickup;
  }

  .drop-circle {
    color: $mapDrop;
  }

  .one-line-block {
    float: right;
    margin-left: 20px;
  }

  .p-fileupload-buttonbar {
    padding: 0 0 0 10px;
  }
}

.assign-btn {
  float: right;
  margin: 0 15px 9px 0;
}

.attached-docs {
  fieldset {
    margin: 20px 0;
  }

  ul {
    margin: 0;
    padding: 0;

    .panel-div {
      padding: 0 15px;
    }
  }
}

.destination-box {
  background: $destinationBox;
  border-bottom: 1px solid $secondaryThemeColor;
  overflow: auto;
}

.forgot-password-link {
  cursor: pointer;
}

.rental {
  .destination-box {
    background: $destinationBox;
    border-bottom: 1px solid $secondaryThemeColor;
    display: block;
    min-height: 186px;
    overflow: auto;
  }
}

.pricing-bundle {
  min-height: 196px;
}

.fa-trash {
  cursor: pointer;
}

.fa-pencil {
  cursor: pointer;
  padding-left: 5px;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.at-bottom {
  bottom: 0;
  position: absolute;
}

.assign-search-input-layout {
  margin: 15px 0 10px;
}

.type-of-passenger {
  margin-top: 10px;
}

.m-20 {
  margin: 20px;
}

.emergency-note {
  color: $defaultThemeColor;
}

.route-map-height {
  height: 446px;
}

.disabled-row {
  background: $stateDefaultBorderColor;
}

.enabled-row {
  background: $white;
}

.page-header-text {
  min-height: 45px;
  position: relative;

  & div {
    bottom: 0;
    font-weight: bold;
    height: 30%;
    margin: auto;
    position: absolute;
    top: 0;
  }
}

.custom-grid {
  text-align: left;

  .grid-header {
    background: #EDEDED;
    border-radius: 3px;
    color: #1E1E1E;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-left: 0;
    opacity: .7;
    padding: 10px;
  }

  .text-center {
    margin: auto 0;
  }

  .custom-col-16 {
    margin: auto;
    width: 16%;
  }

  .custom-col-12 {
    margin: auto;
    width: 12%;
  }
}

.platform-summery-container {
  background-color: #FFF;
  border: 1px solid #DDD;
  box-shadow: 0 1px 1px #000;
  padding: 4% 2%;
  text-align: center;
  width: 96%;

  .summery-icon {
    padding-bottom: 4%;
    width: 100%;
  }

  .summery-count {
    font-size: 25px;
    font-weight: bold;
    width: 100%;
  }

  .summery-title {
    font-size: 15px;
    font-weight: bold;
    padding-top: 4%;
  }
}

.form-border {
  border-radius: 4px;
  padding: 5px;

  &.side-popup {
    height: 100vh;

    .list-data {
      height: 97%;
      overflow-x: hidden;

      .content-scroll {
        height: 500px;
        overflow-x: hidden;
        overflow-y: hidden;

        table {
          // border: none;
          border: 1px solid #9E9E9E;
          border-collapse: separate;
          border-spacing: 0;
          margin: 0;
          table-layout: fixed;
          white-space: nowrap;

          tbody {
            td {
              &:last-child {
                background: #FFF;
                position: sticky;
                right: 0;
                z-index: 1;
              }
            }

            th {
              background: #FFF;
              left: 0;
              position: relative;
              // position: sticky;
              z-index: 1;
            }
          }
        }

        table {
          td {
            border: 1px solid #9E9E9E;
            padding: .5rem 1rem;
          }

          th {
            border: 1px solid #9E9E9E;
            padding: .5rem 1rem;
          }
        }

        table {
          thead {
            th {
              background: #FFF;
              padding: 3px;
              position: sticky;
              top: 0;
              width: 25vw;
              z-index: 1;

              &:last-child {
                position: sticky;
                right: 0;
                z-index: 2;
              }

              &:first-child {
                left: 0;
                position: sticky;
                z-index: 2;
              }
            }
          }
        }

        table {
          td {
            background: #FFF;
            padding: 0;
            text-align: center;
          }
        }

        caption {
          left: 0;
          padding: .25rem;
          position: sticky;
          text-align: left;
        }

        // [role='region'][aria-labelledby][tabindex] {
        //   max-height: 500px;
        //   overflow: auto;
        //   width: 100%;
        // }

        // [role='region'][aria-labelledby][tabindex]:focus {
        //   box-shadow: 0 0 .5em rgba(0, 0, 0, .5);
        //   outline: 0;
        // }

        // [role='region'][aria-labelledby][tabindex] {
        //   max-height: 500px;
        //   overflow: auto;
        //   width: 100%;

        //   &:focus {
        //     box-shadow: 0 0 .5em rgba(0, 0, 0, .5);
        //     outline: 0;
        //   }
        // }

        // [role='region'] {
        //   [aria-labelledby] {
        //     [tabindex] {
        //       max-height: 500px;
        //       overflow: auto;
        //       width: 100%;

        //       &:focus {
        //         box-shadow: 0 0 .5em rgba(0, 0, 0, .5);
        //         outline: 0;
        //       }
        //     }
        //   }
        // }

        [role='region'][aria-labelledby][tabindex] {
          max-height: 500px;
          overflow: auto;
          width: 100%;
        }
      
        [role='region'][aria-labelledby][tabindex]:focus {
          box-shadow: 0 0 .5em #000;
          outline: 0;
        }

        .dayys {
          // sass-lint:disable no-important
          padding: 20px !important;

          &.background {
            // sass-lint:disable no-important
            background-color: #F5F6FA !important;
          }

          &.header {
            color: #212736;
            font-family: Roboto;
            font-size: 18px;
            font-stretch: normal;
            font-style: normal;
            font-weight: bold;
            letter-spacing: normal;
            line-height: normal;
            text-align: center;
          }
        }

        .slots {
          color: #212736;
          cursor: pointer;
          font-size: 18px;
          font-stretch: normal;
          font-style: normal;
          font-weight: bold;
          letter-spacing: normal;
          line-height: normal;
          text-align: center;

          &.background {
            background-color: #F5F6FA;
            border: 0;
            border-top: 1px solid #CCC;
            color: #212736;
            font-size: 18px;
            font-stretch: normal;
            font-style: normal;
            font-weight: bold;
            height: 48px;
            letter-spacing: normal;
            line-height: normal;
            padding: 12px 0;
            text-align: center;

            .time {
              right: -24px;
              top: 12px;
              z-index: 1;
            }
          }

          &.column-background {
            background-color: #F5F6FA;
            position: sticky;
            // sass-lint:disable no-important
            padding: 16px 40px !important;
          }

          &.details {
            .name {
              color: #212736;
              font-family: Roboto;
              font-size: 15px;
              font-stretch: normal;
              font-style: normal;
              font-weight: 500;
              letter-spacing: normal;
              line-height: normal;
            }

            .global-time {
              color: #707070;
              font-family: Roboto;
              font-size: 14px;
              font-stretch: normal;
              font-style: normal;
              font-weight: normal;
              letter-spacing: normal;
              line-height: normal;
              margin: 10px 0 0;
            }

            .all-slots {
              background: #FFF;
              color: #707070;
              font-family: Roboto;
              font-size: 13px;
              font-stretch: normal;
              font-style: normal;
              font-weight: normal;
              letter-spacing: normal;
              line-height: 1.54;
              margin: 10px 0 0;
              padding: 2px;
            }

            .trip-status {
              .label {
                font-family: Roboto;
                font-size: 12px;
                font-stretch: normal;
                font-style: normal;
                font-weight: normal;
                letter-spacing: normal;
                line-height: normal;

                &.upcoming {
                  color: #0B70B0;
                }

                &.completed {
                  color: #DB7B20;
                }
              }

              .value {
                font-family: Roboto;
                font-size: 14px;
                font-stretch: normal;
                font-style: normal;
                // font-weight: 500;
                font-weight: normal;
                letter-spacing: normal;
                line-height: normal;

                &.upcoming {
                  color: #0B70B0;
                }

                &.completed {
                  color: #DB7B20;
                }
              }
            }

            .hour-box {
              padding: 16px 0;
              width: 100%;

              .time {
                display: flex;
                height: 30px;
                margin: 2px 0;
                width: 100%;

                .available {
                  background: #A5D6A7;
                  height: 30px;
                  width: calc(100% / 5);
                }

                .pto {
                  background: #BDCCE3;
                  height: 30px;
                  width: calc(100% / 5);
                }

                .not-in-slt {
                  background: #FFEAAE;
                  height: 30px;
                  width: calc(100% / 5);
                }

                .not-started {
                  background: #FFF;
                  height: 30px;
                  width: calc(100% / 5);
                }

                .busy {
                  background-color: #EF9A9A;
                  height: 30px;
                  width: calc(100% / 5);
                }
              }
            }
          }
        }

        .load-more-btn {
          color: #303030;
          cursor: pointer;
          margin: 10px 30px 10px 0;

          &:hover {
            color: #007AD9;
          }
        }
      }
      .assign-driver-scroll {
        height: 650px;
        overflow-x: hidden;
        overflow-y: hidden;

        table {
          // border: none;
          border: 1px solid #9E9E9E;
          border-collapse: separate;
          border-spacing: 0;
          margin: 0;
          table-layout: fixed;
          white-space: nowrap;

          tbody {
            td {
              &:last-child {
                background: #FFF;
                position: sticky;
                right: 0;
                z-index: 1;
              }
            }

            th {
              background: #FFF;
              left: 0;
              position: relative;
              // position: sticky;
              z-index: 1;
            }
          }
        }

        table {
          td {
            border: 1px solid #9E9E9E;
            padding: .5rem 1rem;
          }

          th {
            border: 1px solid #9E9E9E;
            padding: .5rem 1rem;
          }
        }

        table {
          thead {
            th {
              background: #FFF;
              padding: 3px;
              position: sticky;
              top: 0;
              width: 25vw;
              z-index: 1;

              &:last-child {
                position: sticky;
                right: 0;
                z-index: 2;
              }

              &:first-child {
                left: 0;
                position: sticky;
                z-index: 2;
              }
            }
          }
        }

        table {
          td {
            background: #FFF;
            padding: 0;
            text-align: center;
          }
        }

        caption {
          left: 0;
          padding: .25rem;
          position: sticky;
          text-align: left;
        }

        [role='region'][aria-labelledby][tabindex] {
          max-height: 500px;
          overflow: auto;
          width: 100%;
        }
      
        [role='region'][aria-labelledby][tabindex]:focus {
          box-shadow: 0 0 .5em #000;
          outline: 0;
        }

        .dayys {
          // sass-lint:disable no-important
          padding: 20px !important;

          &.background {
            // sass-lint:disable no-important
            background-color: #F5F6FA !important;
          }

          &.header {
            color: #212736;
            font-family: Roboto;
            font-size: 18px;
            font-stretch: normal;
            font-style: normal;
            font-weight: bold;
            letter-spacing: normal;
            line-height: normal;
            text-align: center;
          }
        }

        .slots {
          color: #212736;
          cursor: pointer;
          font-size: 18px;
          font-stretch: normal;
          font-style: normal;
          font-weight: bold;
          letter-spacing: normal;
          line-height: normal;
          text-align: center;

          &.background {
            background-color: #F5F6FA;
            border: 0;
            border-top: 1px solid #CCC;
            color: #212736;
            font-size: 18px;
            font-stretch: normal;
            font-style: normal;
            font-weight: bold;
            height: 48px;
            letter-spacing: normal;
            line-height: normal;
            padding: 12px 0;
            text-align: center;

            .time {
              right: -24px;
              top: 12px;
              z-index: 1;
            }
          }

          &.column-background {
            background-color: #F5F6FA;
            position: sticky;
            // sass-lint:disable no-important
            padding: 16px 40px !important;
          }

          &.details {
            .name {
              color: #212736;
              font-family: Roboto;
              font-size: 15px;
              font-stretch: normal;
              font-style: normal;
              font-weight: 500;
              letter-spacing: normal;
              line-height: normal;
            }

            .global-time {
              color: #707070;
              font-family: Roboto;
              font-size: 14px;
              font-stretch: normal;
              font-style: normal;
              font-weight: normal;
              letter-spacing: normal;
              line-height: normal;
              margin: 10px 0 0;
            }

            .all-slots {
              background: #FFF;
              color: #707070;
              font-family: Roboto;
              font-size: 13px;
              font-stretch: normal;
              font-style: normal;
              font-weight: normal;
              letter-spacing: normal;
              line-height: 1.54;
              margin: 10px 0 0;
              padding: 2px;
            }

            .trip-status {
              .label {
                font-family: Roboto;
                font-size: 12px;
                font-stretch: normal;
                font-style: normal;
                font-weight: normal;
                letter-spacing: normal;
                line-height: normal;

                &.upcoming {
                  color: #0B70B0;
                }

                &.completed {
                  color: #DB7B20;
                }
              }

              .value {
                font-family: Roboto;
                font-size: 14px;
                font-stretch: normal;
                font-style: normal;
                // font-weight: 500;
                font-weight: normal;
                letter-spacing: normal;
                line-height: normal;

                &.upcoming {
                  color: #0B70B0;
                }

                &.completed {
                  color: #DB7B20;
                }
              }
            }

            .hour-box {
              padding: 16px 0;
              width: 100%;

              .time {
                display: flex;
                height: 30px;
                margin: 2px 0;
                width: 100%;

                .available {
                  background: #A5D6A7;
                  height: 30px;
                  width: calc(100% / 5);
                }

                .pto {
                  background: #BDCCE3;
                  height: 30px;
                  width: calc(100% / 5);
                }

                .not-in-slt {
                  background: #FFEAAE;
                  height: 30px;
                  width: calc(100% / 5);
                }

                .not-started {
                  background: #FFF;
                  height: 30px;
                  width: calc(100% / 5);
                }

                .busy {
                  background-color: #EF9A9A;
                  height: 30px;
                  width: calc(100% / 5);
                }
              }
            }
          }
        }

        .load-more-btn {
          color: #303030;
          cursor: pointer;
          margin: 10px 30px 10px 0;

          &:hover {
            color: #007AD9;
          }
        }
      }
    }
  }
}

p-datatable {
  table {
    tbody {
      tr {
        &.p-rowgroup-header {
          background-color: #E4E4E4;
          border: 1px solid #CCC;
          height: 40px;
        }

        &.p-widget-content {
          height: 30px;
          background: transparent;
        }
      }
    }

    thead {
      th {
        color: #444;
        font-weight: bold;
        height: 40px;
      }
    }
  }
}

.track-my-location-container {
  background: #EFF2F7;
  background-size: cover;
  display: table;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  .track-my-location {
    .header {
      .contact-info {
        display: inline-block;
        min-width: 120px;
      }
    }

    .seperator {
      background-color: #CCC;
      height: 1px;
      width: 100%;
    }

    .driver-logo {
      border: 1px solid #808080;
      border-radius: 50%;
      height: 150px;
      width: 150px;
    }

    .content-header {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: left;
    }

    .map-container {
      height: 400px;
    }

    .content {
      margin-bottom: 10px;

      .content-info {
        font-size: 13px;
        text-align: left;

        .data-info {
          color: #A2A2A2;
        }

        .data-info-status {
          color: #008000;
          font-weight: bold;
        }

        div {
          margin-bottom: 15px;
        }

        p {
          margin-bottom: 15px;
          word-wrap: break-word;

          .start {
            color: #008000;
            margin-right: 2px;
          }

          .end {
            color: #F00;
            margin-right: 2px;
          }
        }

        .star {
          i {
            display: inline-block;
            margin: 0 3px;
            text-align: center;
          }

          .active {
            color: $defaultThemeColor;
          }
        }

        table {
          background-color: transparent;
          border-collapse: collapse;
          border-spacing: 0;
          padding: 0 10px;
          width: 100%;

          tbody {
            border-color: inherit;
            display: table-row-group;
            vertical-align: middle;

            tr {
              height: 29px;

              .value {
                color: #A2A2A2;
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.track-my-location-error {
  font-size: 16px;
  left: 30%;
  position: absolute;
  text-align: center;
  top: 50%;
}

.bold-status {
  color: #000;
  font-weight: bold;
}

.booking-details {
  .booking-details-header {
    .trip-id {
      font-size: 21px;
      font-weight: bold;
      margin: 15px 10px;
    }

    .trip-time {
      font-size: 14px;
      margin: 15px 10px;

      i {
        margin: 0 5px;
      }
    }

    .amount {
      font-size: 31px;
      font-weight: bold;
      padding: 43px 20px;
      text-align: center;
    }

    .trip-status {
      margin: 15px 10px;

      .key {
        color: #333;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }

      .value {
        color: #00B911;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .address-container {
    .start-point {
      background-color: #00B911;
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }

    .divider {
      background-color: #CCC;
      height: 80px;
      margin-left: 3px;
      margin-top: 5px;
      width: 3px;
    }

    .end-point {
      background-color: #F00;
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }

    p {
      margin: 0;
      min-height: 25px;
    }

    .time {
      font-size: 14px;
      font-weight: bold;
      padding-bottom: 10px;
    }
  }

  .booking-detail-container {
    .header {
      border-left: 5px solid #00B911;
      font-size: 16px;
      font-weight: bold;
      padding: 8px 30px;
    }

    table {
      padding: 0 25px;
      width: 100%;

      tr {
        height: 35px;

        td {
          &.key {
            color: #9B9B9B;
            width: 20%;
          }

          &.statistic-key {
            color: #9B9B9B;
            width: 50%;
          }

          &.statistic-key-highlight {
            color: #000;
            font-weight: bold;
          }
        }
      }
    }

    .rating {
      margin-top: 10px;
      padding: 0 27px;

      .rating-header {
        color: #9B9B9B;
        margin-bottom: 30px;
      }

      .rating-container {
        margin-left: -21px;

        .rating-stars {
          background-color: #FFF;
          border: 1px solid #9B9B9B;
          float: left;
          margin-left: 21px;
          margin-top: -15px;
          padding: 6px;
          text-align: center;
          width: 115px;

          i {
            color: #9B9B9B;
            padding: 0 2px;

            &.active {
              color: $defaultThemeColor;
            }
          }
        }

        .rating-comments {
          border: 1px solid #9B9B9B;
          padding: 5%;
          width: 95%;
        }
      }
    }
  }
}

.grid-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    &.source-location {
      &::before {
        color: #008000;
        content: '•';
        display: inline-block;
        font-size: 18px;
        margin-left: -12px;
        width: 12px;
      }
    }

    &.destination-location {
      &::before {
        color: #F00;
        content: '•';
        display: inline-block;
        font-size: 18px;
        margin-left: -12px;
        width: 12px;
      }
    }
  }
}

.vehicle-icon-background {
  background-color: #E1E3E7;
}

.cartype-icon {
  .p-dropdown-panel {
    .p-dropdown-item {
      &:not(.p-state-highlight) {
        &:hover {
          background-color: $carTypeDropdownHover;
        }
      }
    }
  }

  .p-dropdown-items {
    .p-state-highlight {
      background-color: $carTypeIconHighlight;
    }
  }
}

.car-icon-0 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -847px -418px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-1 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -60px -57px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-2 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -250px -57px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-3 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -445px -57px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-4 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -648px -57px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-5 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -852px -57px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-6 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -60px -148px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-7 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -250px -148px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-8 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -445px -148px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-9 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -648px -148px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-10 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -852px -148px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-11 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -60px -233px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-12 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -250px -233px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-13 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -445px -233px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-14 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -648px -233px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-15 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -852px -233px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-16 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -60px -327px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-17 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -250px -327px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-18 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -445px -327px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-19 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -648px -327px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-20 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -852px -327px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-21 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -60px -418px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-22 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -250px -418px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-23 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -445px -418px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-24 {
  background-image: url('/assets/images/temp/car_type_icons-2.png');
  background-position: -648px -418px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

p-panelmenu {
  p-panelmenusub {
    ul {
      li {
        background-color: #F6F6F6;

        a {
          padding: 12px 16px 10px;

          span {
            &.p-menuitem-icon {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

p-sidebar {
  .p-sidebar {
    padding: 0;

    .p-sidebar-close {
      color: #FFF;
      cursor: pointer;
      margin: 10px;

      &:hover {
        color: #FFF;
      }
    }
  }

  .notification-container {
    height: 100vh;

    .header {
      background-color: $defaultThemeColor;
      color: #FFF;
      padding: 15px 10px;
      text-align: center;
    }

    .content {
      padding: 10px 20px;
    }

    .header-heading {
      margin: 0;
      text-align: left;
    }
  }

  .search-location-container {
    height: 100vh;

    .header {
      background-color: $defaultThemeColor;
      color: #FFF;
      padding: 15px 10px;
      text-align: center;
    }

    .header-heading {
      margin: 0;
      text-align: left;
    }

    .form-field {
      input {
        &[type='text'] {
          padding-right: 30px;
        }
      }
    }
  }
}

.free-car-icon {
  background: url('/assets/images/temp/taxi_free.png');
  height: 36px;
  width: 30px;
}

.busy-car-icon {
  background: url('/assets/images/temp/taxi_busy.png');
  height: 36px;
  width: 30px;
}

.idle-car-icon {
  background: url('/assets/images/temp/taxi_idle.png');
  height: 36px;
  width: 30px;
}

.google-map-info-marker {
  font-family: $fontFamily;
  font-size: 12px;
  font-weight: bold;

  .row-item {
    margin: 5px;

    .key {
      color: #000;
    }

    .value {
      color: $defaultThemeColor;
    }
  }
}

.single-line-form-table {
  width: 100%;

  .form-title {
    width: 20%;
  }

  .form-control {
    width: 30%;

    .p-dropdown {
      .p-dropdown-label {
        padding: 3px 0 0 5px;
      }
    }
  }

  .form-errors {
    text-align: end;
    width: 50%;
  }
}

.not-record-text {
  font-size: 16px;
  font-weight: bold;
}

.emergency-map-container {
  box-shadow: 1px 1px 1px #AAA;
  height: 400px;
  margin-top: 10px;
}

.arrange-vehicle-container {
  p-pickList {
    .p-widget-header {
      background: transparent;
      border: 0;
    }

    .p-picklist-responsive {
      .p-picklist-buttons {
        button {
          margin: 10px auto;
          width: 50%;
        }
      }

      .p-picklist-listwrapper {
        .p-picklist-list {
          height: 33em;
          padding: 5px;

          li {
            border-bottom: 1px solid $defaultThemeColor;

            &:hover {
              background: #AAA;
              border-bottom: 1px solid $defaultThemeColor;
            }

            &.p-state-highlight {
              background: #AAA;
            }
          }
        }
      }
    }
  }
}

.ride-share-details {
  margin-bottom: 20px;

  tr {
    height: 25px;
  }
}

.ride-share-passenger-list-container {
  table {
    border-collapse: collapse;
    color: #1E1E1E;
    text-align: center;

    tr {
      height: 35px;

      th {
        background: #EDEDED;
        border: 1px solid #AAA;
      }

      td {
        border: 1px solid #AAA;
      }
    }
  }
}

.bidding-detail-container {
  border-collapse: collapse;
  color: #1E1E1E;
  text-align: center;

  tr {
    height: 40px;

    &.odd {
      background-color: #F2F2F2;
    }

    th {
      border-top: 1px solid #AAA;
      height: 50px;
    }
  }
}

.booking-fares-container {
  table {
    border-collapse: collapse;
    color: #1E1E1E;
    margin-top: 4px;
    text-align: center;

    tr {
      height: 35px;

      th {
        background: #EDEDED;
        border: 1px solid #AAA;
      }

      td {
        border: 1px solid #AAA;
      }
    }
  }
}

.p-tabview {
  &.p-tabview-top {
    background: #F6F6F6;
    border: 0;
    padding: 0;

    .p-tabview-nav {
      li {
        &.p-menuitem-link-active {
          background: #FFF;
        }

        &:not(.p-menuitem-link-active) {
          &:not(.p-state-disabled) {
            &:hover {
              background: #CCC;
              border: 0;
              color: #FFF;
            }
          }
        }

        &:focus {
          outline: 0;
        }
      }

      // .p-state-default {
        background: #A0A0A0;
      // }

      a {
        color: #000;
      }
    }
  }
}

.driver-inline-flex {
  display: flex;
  flex-wrap: wrap;
}

p-toast {
  .p-toast {
    z-index: 9000 !important; // sass-lint:disable-line no-important
  }
}

.p-multiselect-header {
  .p-multiselect-filter-container {
    .p-multiselect-filter-icon {
      left: .28em;
      position: absolute;
      top: 1.1em;
    }
  }
}

.pi-spinner ::before {
  content: none !important; // sass-lint:disable-line no-important
}

.email-loader {
  height: 16px;
  position: absolute;
  right: 15px;
  top: 37px;
  width: 16px;
}

.p-widget-overlay {
  z-index: 100 !important; // sass-lint:disable-line no-important
}

.multiple-checked {
  display: contents;
  position: absolute;
  right: 124px;
}

.backgroud-patch {
  background: #FFF;
  border-radius: 6px;
  padding: 19px;
}

.p-paginator {
  .p-paginator-pages {
    .p-paginator-page {
      .p-menuitem-link-active {
        background-color: #06A0FF;
        color: #FFF;
      }
    }
  }
}

.head-heading {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  word-spacing: 0;
}

.google-icon {
  text-align: center;
}

.containar-layout {
  background: $white;
  border-radius: 10px;
  height: auto;
  margin: 20px auto;
  margin-top: 10px;
  min-height: 650px;
  overflow: visible;
  padding: 20px 10px;
  width: 100%;
}

.dived-border {
  border-bottom: 2px solid #ECEDF3;
  padding-bottom: 10px;
}

.top-dived-border {
  border-top: 2px solid #ECEDF3;
  padding-top: 10px;
}

.driver-profile-pic {
  border-radius: 5px;
  height: 200px;
  width: 200px;
}

.contact-with-countory {
  float: left;
  width: 40%;

  &.right {
    width: 60%;
  }
}

.edit-profile-country-code {
  position: relative;
  top: -6px;
}

.clearfix-container {
  cursor: pointer;
  height: 33px;
  position: relative;
}

.p-0 {
  padding: 0;
}

.p-2-percent {
  padding: 2%;
}

.p-4 {
  padding: 4px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.p-4-percent {
  padding: 4%;
}

.pt-3-percent {
  padding-top: 3%;
}

.pt-6-percent {
  padding-top: 6%;
}

.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-13 {
  padding-top: 13px;
}

.pt-12 {
  padding-top: 12px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-33 {
  padding-top: 33px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.m-0 {
  margin: 0;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-17 {
  margin-top: 17px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-17 {
  margin-left: 17px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.container-height {
  &.h-55 {
    height: 55px;
  }

  &.h-80 {
    height: 80px;
  }

  &.h-100 {
    height: 100px;
  }

  &.h-115 {
    height: 115px;
  }

  &.h-400 {
    height: 400px;
  }

  &.w-10-percent {
    width: 10%;
  }

  &.w-50-percent {
    width: 50%;
  }

  &.w-100-percent {
    width: 100%;
  }

  &.mw-119-percent {
    max-width: 119px;
  }
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-21 {
  font-size: 21px;
}

.hide-extra {
  overflow: hidden;
}

.emergency-map {
  height: 400px;
  margin-left: 20px;
}

.aboutus-update-btn {
  float: right;
  padding: 7px 16px;
}

.partner-logo {
  border-radius: 100%;
  max-width: 100%;
}

.about-company-btn {
  float: right;
  margin-right: 14px;
}

.trip-form {
  .form-field {
    .form-label {
      color: #06A0FF;
      margin: 3px 0;
      text-transform: uppercase;
    }

    p-checkbox {
      padding: 0;

      .p-chkbox-label {
        margin: 0 0 0 8px;
      }
    }
    p-radiobutton {
      .p-radiobutton-box {
        top: 10px;
        position: relative;
      }

      .p-radiobutton-label {
        width: 75px;
        margin: 0 0 0 8px;
        top: 10px;
        position: relative;
      }
    }

    .recurrence-date {
      .p-calendar {
        margin-left: 20px;
        width: 250px;
      }
    }

    .week-dropdown {
      margin: 0 10px;

      .p-widget {
        min-width: 87px;
      }
    }
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.countory-code {
  .p-widget {
    min-width: 30px;
    width: 100%;
  }
}

.week-day {
  margin: 0 45px 20px 0;
  width: 200px;
}

.auto-search-bar {
  .search-field {
    input {
      &[type='text'] {
        font-size: 15px;
        padding-left: 30px;
        padding-right: 10px;
      }
    }
  }

  .search-icon {
    font-size: 15px;
    position: relative;
    bottom: 32px;
    left: 6px;
  }
}

.cancel-bar-action {
  position: relative;
}

.cancel-icon {
  background: #007AFF;
  border-radius: 50%;
  color: #FFF;
  cursor: pointer;
  font-size: 15px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 17px;
  text-align: center;
  top: 37px;
  vertical-align: middle;
  width: 20px;
}

.test-copy-icon {
  cursor: pointer;
  font-size: 15px;
  padding: 4px;
}

.emergency-container {
  background-color: #FFF;
  border-radius: 5px;
  padding: 20px 10px;
}

.synk-data-icon {
  background: url('/assets/images/temp/synk_icon1.png');
  background-position: -77px -36px;
  background-repeat: no-repeat;
  height: 47px;
  width: 41px;
}

.info-box-inner-circle {
  background-color: #FFEEEB;
  border-radius: 50%;
  color: #EC4526;
  font-size: 22px;
  height: 54px;
  left: 5px;
  line-height: 54px;
  position: relative;
  text-align: center;
  top: 6px;
  width: 54px;

  .pr-30 {
    background-color: #FFEEEB;
    border-radius: 50%;
    color: #EC4526;
    font-size: 26px;
    height: 47px;
    left: 5px;
    padding: 8px;
    padding-right: 30px;
    position: relative;
    text-align: center;
    top: 5px;
    width: 47px;
  }
}

.gragh-background {
  background-color: $white;
}

.main-panel-background {
  background-color: $white;
  border-radius: 4px;
  padding: 0;
}

.display-none {
  display: none;
}

.padd-10 {
  padding: 6.5px;

  .pt-30 {
    padding: 6.5px;
    padding-top: 30px;
  }
}

.remove-doc {
  color: $gridBtnColorDanger;
}

.edit-doc {
  color: $defaultThemeColor;
}

.chart-box-border {
  box-shadow: 1px 4px 10px #888;
  padding: 20px 10px;
}

.row-box {
  box-shadow: 1px 4px 10px #888;
  display: grid;
  margin: 10px;
  padding: 20px 10px;
}

.chart-box-title {
  padding: 15px;
  text-align: center;
}

.scheduler-layout {
  background: $white;
  height: auto;
  margin-top: 10px;
  min-height: 650px;
  padding: 10px;
  padding-bottom: 20px;
}

.row-width {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
}

.scheduler-output-header {
  border-bottom: 1px solid #ECEDF3;
  height: 70px;
}

.trip-details-header {
  color: #06A0FF;
  margin-bottom: 7px;
  margin-top: 20px;
  padding: 0;
  text-transform: uppercase;
}

.trip-details-lable {
  color: #808080;
  margin-bottom: 10px;
}

.trip-details-value {
  margin-bottom: 10px;
  overflow-wrap: break-word;
  padding-left: 5px;
  word-wrap: break-word;
}

.line-distance {
  line-height: 1.4;
}

.leble-green {
  color: #008000;
  font-size: 12px;
  padding-left: 10px;
}

.b-red {
  background: #F00;
}

.green {
  color: #008000;
}

.grey {
  color: #808080;
}

.red {
  color: #F00;
}

.black {
  color: #000;
}

.leble-red {
  color: #F00;
  font-size: 12px;
  padding-left: 10px;
}

.back-icon {
  cursor: pointer;
  margin-right: 20px;

  i {
    color: #06A0FF;
    font-size: 22px;
    width: 20px;
  }
}

.details-header-id {
  font-size: 19px;
  font-weight: 500;
  padding: 0;
}

.trip-id-header {
  font-size: 17px;
  font-weight: 500;
  padding: 0;
  padding-left: 10px;
}

.panel-box {
  margin-top: 25px;
  text-align: center;
}

.panel-box-loayout {
  margin: auto 0;
}

.non-readonly-background {
  .form-field {
    input {
      &:read-only {
        background: #FFF;
      }
    }
  }
}

.synk-data-update {
  float: right;
  margin: 8px;
  min-width: 200px;
  padding: 5px;
}

.refresh-btn {
  bottom: 4px;
  height: 30px;
  margin: 32px 0 0 10px !important; // sass-lint:disable-line no-important
  top: 2px;
}

.refresh-btn1 {
  height: 30px;
  margin: 32px 0 0 10px !important; // sass-lint:disable-line no-important
  top: 2px;
}

.info-box {
  background-color: $white;
  color: #354052;
  display: inline-block;
  font-size: 12px;
  min-height: 340px;
  padding: 23px 10px;
  width: 100%;

  @media screen and (max-width: 1200px) {
    min-height: 380px;
  }

  .info-box-text {
    color: #7A7D87;
    font-size: 13px;
    padding: 12.5px 6.5px;
  }

  .info-box-number {
    color: #06A0FF;
    font-size: 16px;
    padding: 12.5px 6.5px;
    text-align: right;
  }

  .info-box-title {
    font-weight: 500;
    padding: 0 6.5px 12.5px;
    text-transform: uppercase;
  }
}

.info-box-outter-circle {
  animation: glow 1s infinite alternate;
  border: solid .5px #DE4632;
  border-radius: 50%;
  display: inline-block;
  height: 65px;
  width: 65px;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px -5px #DE4632;
    color: #DE4632;
  }

  to {
    box-shadow: 0 0 5px 5px #DE4632;
    color: rgb(214, 30, 6);
  }
}

@keyframes glow-text {
  from {
    color: $defaultThemeColor;
    text-shadow: 0 0 1px #FFF;
  }

  to {
    color: $defaultThemeColor;
    text-shadow: 0 0 1px #007DCD;
  }
}

.dashboardv2 {
  background-color: #F6F6F6;
  position: relative;
  top: -8px;
}

.eta-container {
  background: #FFF;
  border: 1px solid #808080;
  border-radius: 5px;
  bottom: 33px;
  left: 41px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1000;

  .eta-container-lable {
    color: #808080;
    margin-bottom: 3px;
  }
}

full-calendar {
  .fc-bg {
    tr {
      .fc-today {
        background: #FFF;
      }
    }
  }
}

.center-align {
  text-align: center;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.remove-float {
  clear: both;
}

.inline-block {
  display: inline-block;
}

.vehicle-description {
  color: #828080;
  font-size: 13px;
}

.delete-vehicle-text {
  color: #F00;
  float: right;
  font-size: 18px;
  padding-right: 14px;
}

.provider-location {
  .p-autocomplete {
    .p-autocomplete-panel {
      max-height: 75vh !important; // sass-lint:disable-line no-important
    }
  }
}

.provider-address {
  color: #A2A2A2;
}

.map-adderess-container {
  height: 84px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.estimation-one-way-head {
  margin: 0 2px 2px 0;
}

.header-dialog-heading {
  border-bottom: 1px solid #C8C8C8;
  font-weight: 500;
}

.header-dialog-content {
  border-bottom: 1px solid #C8C8C8;
  padding: 15px 10px;
}

.driver-calendar {
  background: url('/assets/images/temp/calendar.svg');
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
}

.less-than {
  background: url('/assets/images/temp/less.svg');
  background-repeat: no-repeat;
  cursor: pointer;
  height: 16px;
  margin: 0 0 0 30px;
  object-fit: contain;
  opacity: .8;
  width: 9px;
}

.greater-than {
  background: url('/assets/images/temp/greater.svg');
  background-repeat: no-repeat;
  cursor: pointer;
  height: 16px;
  margin: 0 0 0 30px;
  object-fit: contain;
  opacity: .8;
  width: 9px;
}

.day {
  background: url('/assets/images/temp/day.svg');
  background-repeat: no-repeat;
  cursor: pointer;
  height: 16px;
  margin-right: 5px;
  object-fit: contain;
  width: 16px;

  &.active {
    background: url('/assets/images/temp/day-active.svg');
  }
}

.week {
  background: url('/assets/images/temp/week.svg');
  background-repeat: no-repeat;
  cursor: pointer;
  height: 16px;
  margin-right: 5px;
  object-fit: contain;
  width: 18px;

  &.active {
    background: url('/assets/images/temp/week-active.svg');
  }
}

// p-dialog {

// }

.week-name {
  color: #212736;
  font-family: Roboto;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: .83;
  margin: 0 0 0 15px;
}

.align-center {
  align-items: center;
}

.fa {
  &.fa-pickup {
    background-image: url('/assets/images/temp/pick-up.svg');
    background-repeat: no-repeat;
    color: #FFF;
    height: 20px;
    width: 25px;
  }

  &.fa-dropoff {
    background-image: url('/assets/images/temp/drop-off.svg');
    background-repeat: no-repeat;
    color: #FFF;
    height: 20px;
    width: 25px;
  }
}

.mr-l-208 {
  margin-left: 208px;
}

.first-busy {
  border-bottom: solid 2px #5D0000;
  border-left: solid 2px #5D0000;
  border-top: solid 2px #5D0000;
}

.last-busy {
  border-bottom: solid 2px #5D0000;
  border-right: solid 2px #5D0000;
  border-top: solid 2px #5D0000;
}

.other-busy {
  border-bottom: solid 2px #5D0000;
  border-top: solid 2px #5D0000;
}

.marks {
  padding: 10px;

  .slot-type {
    border-radius: 4.5px;
    height: 9px;
    width: 9px;

    &.available {
      background-color: #D2EAD3;
      border: solid 1.1px #A5D6A7;
    }

    &.unavailable {
      background-color: #FFD868;
      border: solid 1.1px #F3B70C;
    }

    &.busy {
      background-color: #F7CCCC;
      border: solid 1.1px #EF9A9A;
    }

    &.pto {
      background: #E4EAF8;
      border: 1.1px solid #889CBC;
    }
  }

  .grp-name {
    color: #424242;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    padding-left: 10px;
  }
}

.driver-schedule {
  position: absolute;
  width: 170px;

  .p-calendar {
    .p-inputtext {
      opacity: 0;
    }
  }
}

.p-panelmenu {
  .p-panelmenu-header > a {
    font-weight: 400;
  }

  .p-panelmenu-content {
    // sass-lint:disable no-important
    background: #FFF !important;
    border: 0 !important;
    border-bottom-left-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    border-top: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    color: #333 !important;
    margin-bottom: 2px !important;
    padding: 0 !important;
  }
}

.div-margin {
  margin: 1px;
}

.member-req {
  bottom: 5px;
  position: relative;
}

.recur-after {
  position: relative;
  top: 13px;
}

.div-fax-margin {
  margin-top: 11px;
}

.ui-spinner {
  .ui-spinner-button {
    background: #3B7BFF;
    border: 1px solid #3B7BFF;
    color: #FFF;
    font-weight: 500;
    width: 2em;

    &.ui-spinner-up {
      border-top-right-radius: 3px;
    }

    &.ui-spinner-down {
      border-bottom-right-radius: 3px;
    }
  }
}

.p-checkbox {
  left: -3px;
  top: 8px;

  .p-checkbox-box {
    bottom: 8px;
    position: absolute;
  }
}

// .p-panelmenu {
//   .p-panelmenu-header > a {
//     font-weight: 400;
//   }
// }

// .p-checkbox {
//   left: -3px;
//   top: 8px;
// }

.p-fileupload {
  .p-fileupload-content {
    background: 0;
    border: 0;
  }

  .p-fileupload-buttonbar {
    background: 0;
    border: 0;

    .p-button {
      left: 30px;
    }
  }
}

// .p-fileupload {
//   .p-fileupload-content {
//     background: 0;
//     border: 0;
//   }
// }

.pad-div {
  position: relative;
  right: 7px;
}

.p-dialog {
  .p-dialog-header {
    height: 50px;
    background: #3d4246;
    border: 0;
    color: #fff;
    font-size: 16px;
    padding: 1em 1.1em;
    position: relative;

    .p-dialog-header-icons {
      .p-dialog-header-icon {
      border: 0;
      color: #fff;
      }
    }
  }
}

.p-datatable {
  .p-datatable-tbody > tr {
    &.p-highlight {
      background: #F2F2F2;
      color: #000;
    }

    &:focus {
      outline: none;
      outline-offset: 0;
    }
  }

  .p-sortable-column {
    .p-sortable-column-badge {
      // sass-lint:disable no-important
      display: none !important;
    }
  }
}

// .p-datatable {
//   .p-sortable-column {
//     .p-sortable-column-badge {
//       display: none !important;
//     }
//   }
// }

.p-datatable-resizable-table > .p-datatable-thead > tr > th,
.p-datatable-resizable-table > .p-datatable-tfoot > tr > td,
.p-datatable-resizable-table > .p-datatable-tbody > tr > td {
  // sass-lint:disable no-important
  white-space: normal !important;
  // width: max-content;
}

// .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
//   background: none;
//   border-bottom: 3px solid #3B7BFF;
//   color: #007AD9;
// }

// .p-tabmenu {
//   .p-tabmenu-nav {
//     .p-tabmenuitem {
//       &.p-highlight {
//         .p-menuitem-link {
//           background: none;
//           border-bottom: 3px solid #3B7BFF;
//           color: #007AD9;
//         }
//       }
//     }
// 	}
// }

.trip-id-div {
  left: -7px;
  position: relative;
}

.p-paginator {
  // sass-lint:disable no-important
  background: none !important;
  border: 0 !important;
  position: relative;
  top: 5px;

  .p-paginator-pages .p-paginator-page.p-highlight {
    // sass-lint:disable no-important
    border-radius: 50% !important;
  }
}

.p-dropdown {
  border: 0;
  border-bottom: 1px solid #ECEDF3;
  border-radius: 0;
  position: relative;
  top: 5px;
}

.edit-pro-heading {
  left: 15px;
  position: relative;
}

.change-pwd-btn{
  right: 20px;
  position: relative;
}

.p-sidebar {
  .p-sidebar-header {
    padding: 0;
  }

  .p-sidebar-content {
    padding: 0;
  }
}

.eclipse-tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #212736;
  width: 100%;
}

.eclipse-tooltip2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #212736;
  width: 100%;
}

.trip-id {
  overflow: unset;
  width: auto;
}

.custom-width {
  width: 500px;
}

.custom-user-width {
  width: 250px;
}

.action-btn-column{
  .eclipse-tooltip2 {
    text-overflow: initial;
    width: 110px;
  }
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #E1E3E7;
}

.pickup-location {
  padding: 0 !important;
}

.marks-grid {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
