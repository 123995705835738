.assign-vehicle-container {
  fieldset {
    border: 0;
    color: #444;

    .assign-vehicle-form-container {
      border-right: 1px solid $black;
    }
  }

  .another-drop-location-btn {
    float: right;
    margin-top: 20px;

    .p-button-text {
      padding: 8px 5px 8px 25px;
    }
  }

  .days-container {
    margin: 10px 0;

    .p-button-text {
      padding: 4px 12px;
    }
  }

  .another-drop-location {
    height: 19px;
  }
}


