.company-logo {
  &.sign-in-page {
    background-color: $taxiPulseSecondaryColor;
    text-align: center;
  }

  img {
    max-width: 119px;
  }
}

.sidebar {
  background-color: $taxiPulseSecondaryColor;
  min-height: 100%;
  position: relative;
  top: -10px;

  .sidebar-body{
    overflow-y: scroll;
    height: calc(100vh - 60px);
  }

  .sidebar-panel-menu {
    .p-panelmenu {
      .p-panelmenu-panel {
        .p-panelmenu-header {
          &.p-highlight {
            background-color: #007AD9;
          }

          .p-panelmenu-header-link {
            .p-menuitem-icon {
              background-image: url('/assets/images/temp/sprite_v222.png');
              background-repeat: no-repeat;
              color: #FFF;
              height: 20px;
              width: 25px;

              &.fa-driver-time-menu {
                background-image: url('/assets/images/temp/path.svg');
                background-repeat: no-repeat;
                color: #FFF;
                height: 20px;
                width: 25px;
              }

              &.fa-dashboard-menu {
                //dashboard
                background-position: -31px -32px;
              }

              &.fa-booking-menu {
                // Bookings
                background-position: -31px -128px;
              }

              &.fa-passenger-menu {
                // passengers
                background-position: -31px -273px;
              }

              &.fa-provider-menu {
                // provider
                background-position: -33px -848px;
              }

              &.fa-emergency-menu {
                // emergency
                background-position: -31px -174px;
              }

              &.fa-scheduler-menu {
                // scheduler
                background-position: -81px -128px;
              }

              &.fa-driver-menu {
                // Drivers
                background-position: -31px -320px;
              }

              &.fa-vehicle-menu {
                // Vehicles
                background-position: -31px -225px;
              }

              &.fa-cities-menu {
                // Cities
                background-position: -31px -416px;
              }

              &.fa-app-setting-menu {
                // App Settings
                background-position: -31px -513px;
              }

              &.fa-user-management-menu {
                // User Mangement
                background-position: -31px -752px;
              }

              &.fa-operator-management-menu {
                // operator Mangement
                background-position: -31px -752px;
              }

              &.fa-marketing-menu {
                // Marketings
                background-position: -82px -896px;
              }

              &.fa-setting-menu {
                // Settings
                background-position: -81px -32px;
              }

              &.fa-report-menu {
                // Reports
                background-position: -81px -416px;
              }

              &.fa-company-menu {
                // Companies
                background-position: -31px -464px;
              }
            }

            &.p-menuitem-link-active {
              background: $themeSecondaryHoverColor;
              border-color: $themeSecondaryHoverColor;
              box-shadow: inset 3px 0 0 0 $taxipulseThemeColor;
              color: $taxiPulseSideBarTextIconActiveColor;
              font-weight: 600;

              .p-menuitem-icon {
                background-image: url('/assets/images/temp/sprite_v2.png');
                background-repeat: no-repeat;
                height: 20px;
                width: 25px;

                &.fa-dashboard-menu {
                  //dashboard
                  background-position: -141px -32px;
                }

                &.fa-booking-menu {
                  // Bookings
                  background-position: -141px -128px;
                }

                &.fa-passenger-menu {
                  // passengers
                  background-position: -141px -273px;
                }

                &.fa-provider-menu {
                  // provider
                  background-position: -143px -848px;
                }

                &.fa-emergency-menu {
                  // emergency
                  background-position: -141px -174px;
                }

                &.fa-scheduler-menu {
                  // scheduler
                  background-position: -191px -128px;
                }

                &.fa-driver-menu {
                  // Drivers
                  background-position: -141px -320px;
                }

                &.fa-vehicle-menu {
                  // Vehicles
                  background-position: -141px -225px;
                }

                &.fa-cities-menu {
                  // Cities
                  background-position: -141px -416px;
                }

                &.fa-app-setting-menu {
                  // App Settings
                  background-position: -141px -513px;
                }

                &.fa-user-management-menu {
                  // User Mangement
                  background-position: -141px -752px;
                }

                &.fa-operator-management-menu {
                  // operator Mangement
                  background-position: -31px -752px;
                }

                &.fa-marketing-menu {
                  // Marketings
                  background-position: -191px -896px;
                }

                &.fa-setting-menu {
                  // Settings
                  background-position: -191px -32px;
                }

                &.fa-report-menu {
                  // Reports
                  background-position: -191px -416px;
                }

                &.fa-company-menu {
                  // Companies
                  background-position: -142px -464px;
                }
              }
            }

            &:hover {
              background-color: $themeSecondaryHoverColor;
              border: 0;
              box-shadow: inset 3px 0 0 0 $taxipulseThemeColor;
              color: #131212;
            }
          }
        }
      }

      .p-toggleable-content {
        .p-panelmenu-content {
          .p-submenu-list {
            .p-menuitem {
              .p-menuitem-link {
                .p-menuitem-icon {
                  background-image: url('/assets/images/temp/sprite_v222.png');
                  background-repeat: no-repeat;
                  height: 20px;
                  width: 25px;

                  &.fa-global-time-menu {
                    background-image: url('/assets/images/temp/global-time.svg');
                    background-repeat: no-repeat;
                    color: #FFF;
                    height: 20px;
                    width: 25px;
                  }

                  &.fa-schedule-menu {
                    background-image: url('/assets/images/temp/schedule.svg');
                    background-repeat: no-repeat;
                    color: #FFF;
                    height: 20px;
                    width: 25px;
                  }

                  &.fa-assign-driver-menu {
                    background-image: url('/assets/images/temp/schedule.svg');
                    background-repeat: no-repeat;
                    color: #FFF;
                    height: 20px;
                    width: 25px;
                  }

                  &.fa-about-company-menu {
                    background-position: -32px -560px;
                    // About Company
                  }

                  &.fa-faq-menu {
                    // FAQ
                    background-position: -32px -608px;
                  }

                  &.fa-term-condition-menu {
                    // Term & conditions
                    background-position: -33px -705px;
                  }

                  &.fa-privacy-policy-menu {
                    // Privacy Policy
                    background-position: -33px -657px;
                  }

                  &.fa-manage-user-menu {
                    // Manage Users
                    background-position: -33px -803px;
                  }

                  &.fa-manage-operator-menu {
                    background-position: -33px -803px;
                  }

                  &.fa-manage-role-menu {
                    // Manage Roles
                    background-position: -33px -848px;
                  }

                  &.fa-promocode-menu {
                    // Promocode
                    background-position: -81px -177px;
                  }

                  &.fa-notification-menu {
                    // Notifications
                    background-position: -32px -895px;
                  }

                  &.fa-segment-menu {
                    // Segments
                    background-position: -33px -705px;
                  }

                  &.fa-time-setting-menu {
                    // Time settings
                    background-position: -81px -81px;
                  }

                  &.fa-vehicle-type-menu {
                    // Vehicle Type
                    background-position: -81px -128px;
                  }

                  &.fa-reference-setting-menu {
                    // Reference Setting
                    background-position: -82px -224px;
                  }

                  &.fa-member-login-menu {
                    // Reference Setting
                    background-position: -82px -224px;
                  }

                  &.fa-redius-menu {
                    // Redius Settings
                    background-position: -81px -272px;
                  }

                  &.fa-tax-menu {
                    // Manage Taxes
                    background-position: -82px -320px;
                  }

                  &.fa-appointment-menu {
                    background-position: -82px -320px;
                  }

                  &.fa-ride-share-menu {
                    // Ride Share Settings
                    background-position: -81px -366px;
                  }

                  &.fa-driver-login-logout-report-menu {
                    // Driver login logout
                    background-position: -82px -465px;
                  }

                  &.fa-driver-on-duty-report-menu {
                    // Driver on duty
                    background-position: -82px -512px;
                  }

                  &.fa-driver-mileage-menu {
                    // Driver mileage
                    background-position: -82px -560px;
                  }

                  &.fa-driver-performace-menu {
                    // Driver performance
                    background-position: -80px -608px;
                  }

                  &.fa-car-mileage-menu {
                    //car Mileage
                    background-position: -82px -658px;
                  }

                  &.fa-refferal-credit-menu {
                    // Refferal Credits
                    background-position: -82px -702px;
                  }

                  &.fa-driver-income-menu {
                    // Driver Income
                    background-position: -82px -930px;
                  }

                  &.fa-company-income-menu {
                    // Company Income
                    background-position: -83px -848px;
                  }

                  &.fa-driver-feedback-menu {
                    // Driver feedback
                    background-position: -83px -961px;
                  }

                  &.fa-passenger-feedback-menu {
                    // Passenger feedback
                    background-position: -83px -756px;
                  }

                  &.fa-admin-topup-menu {
                    // Admin Topup
                    background-position: -82px -702px;
                  }

                  &.fa-admin-transaction-menu {
                    // Admin Transaction
                    background-position: -82px -930px;
                  }
                }
              }

              .p-menuitem-link-active {
                .p-menuitem-text {
                  color: $taxipulseThemeColor;
                  font-size: 12px;
                  font-weight: 600;
                }

                .p-menuitem-icon {
                  background-image: url('/assets/images/temp/sprite_v222.png');
                  background-repeat: no-repeat;
                  height: 20px;
                  width: 25px;

                  &.fa-global-time-menu {
                    background-image: url('/assets/images/temp/global-time-active.svg');
                  }

                  &.fa-schedule-menu {
                    background-image: url('/assets/images/temp/schedule-active.svg');
                  }

                  &.fa-assign-driver-menu {
                    background-image: url('/assets/images/temp/schedule-active.svg');
                  }

                  &.fa-about-company-menu {
                    // About Company
                    background-position: -142px -560px;
                  }

                  &.fa-faq-menu {
                    // FAQ
                    background-position: -142px -608px;
                  }

                  &.fa-term-condition-menu {
                    // Term & conditions
                    background-position: -143px -705px;
                  }

                  &.fa-privacy-policy-menu {
                    // Privacy Policy
                    background-position: -143px -657px;
                  }

                  &.fa-manage-user-menu {
                    // Manage Users
                    background-position: -143px -803px;
                  }

                  &.fa-manage-operator-menu {
                    background-position: -143px -803px;
                  }

                  &.fa-manage-role-menu {
                    // Manage Roles
                    background-position: -143px -848px;
                  }

                  &.fa-promocode-menu {
                    // Promocode
                    background-position: -191px -177px;
                  }

                  &.fa-notification-menu {
                    // Notifications
                    background-position: -142px -895px;
                  }

                  &.fa-segment-menu {
                    // Segments
                    background-position: -143px -705px;
                  }

                  &.fa-time-setting-menu {
                    // Time settings
                    background-position: -191px -81px;
                  }

                  &.fa-vehicle-type-menu {
                    // Vehicle Type
                    background-position: -191px -128px;
                  }

                  &.fa-reference-setting-menu {
                    // Reference Setting
                    background-position: -192px -224px;
                  }

                  &.fa-member-login-menu {
                    // Reference Setting
                    background-position: -192px -224px;
                  }

                  &.fa-redius-menu {
                    // Redius Settings
                    background-position: -191px -272px;
                  }

                  &.fa-tax-menu {
                    // Manage Taxes
                    background-position: -192px -320px;
                  }

                  &.fa-appointment-menu {
                    // Manage Taxes
                    background-position: -192px -320px;
                  }

                  &.fa-ride-share-menu {
                    // Ride Share Settings
                    background-position: -191px -366px;
                  }

                  &.fa-driver-login-logout-report-menu {
                    // Driver login logout
                    background-position: -192px -465px;
                  }

                  &.fa-driver-on-duty-report-menu {
                    // Driver on duty
                    background-position: -192px -512px;
                  }

                  &.fa-driver-mileage-menu {
                    // Driver mileage
                    background-position: -192px -560px;
                  }

                  &.fa-driver-performace-menu {
                    // Driver performance
                    background-position: -190px -608px;
                  }

                  &.fa-car-mileage-menu {
                    //car Mileage
                    background-position: -192px -658px;
                  }

                  &.fa-refferal-credit-menu {
                    // Refferal Credits
                    background-position: -192px -702px;
                  }

                  &.fa-driver-income-menu {
                    // Driver Income
                    background-position: -191px -930px;
                  }

                  &.fa-company-income-menu {
                    // Company Income
                    background-position: -193px -848px;
                  }

                  &.fa-driver-feedback-menu {
                    // Driver feedback
                    background-position: -192px -961px;
                  }

                  &.fa-passenger-feedback-menu {
                    // Passenger feedback
                    background-position: -193px -756px;
                  }

                  &.fa-admin-topup-menu {
                    // Admin Topup
                    background-position: -192px -702px;
                  }

                  &.fa-admin-transaction-menu {
                    // Admin Transaction
                    background-position: -191px -930px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .company-logo {
    background-color: none;
    text-align: center;
  }

  .p-menuitem-text {
    font-size: 14px;
  }

  .p-widget-content {
    background-color: $taxiPulseSecondaryColor;
    border: 0 none;

    .p-menuitem-link {
      border-left: 3px solid $taxiPulseSecondaryColor;
      color: $sidebarMenuTextColor;
      height: 40px;
      padding: 8px 4px 3px;

      &:hover {
        background-color: $themeSecondaryHoverColor;
        border-left: 3px solid $taxipulseThemeColor;
        color: $sidebarMenuHoverTextColor;
      }

      &:active {
        background-color: $themeSecondaryHoverColor;
        border-left: 0 solid $taxipulseThemeColor;
        color: $sidebarMenuHoverTextColor;
        padding: 8px 4px 3px;
      }

      .p-menuitem-text {
        font-size: 12px;
      }
    }

    .p-menuitem {
      &:not(.p-state-active) {
        &:hover {
          background-color: $themeSecondaryHoverColor;
          border-color: $themeSecondaryHoverColor;
          color: $sidebarMenuHoverTextColor;
        }

        &:active {
          background-color: $themeSecondaryHoverColor;
          border-color: $themeSecondaryHoverColor;
          border-left: 3px solid $taxipulseThemeColor;
          color: $sidebarMenuHoverTextColor;
        }
      }

      .p-state-active {
        background: $themeSecondaryHoverColor;
        border-color: $themeSecondaryHoverColor;
        border-left: 3px solid $taxipulseThemeColor;

        .p-menuitem-text {
          color: $taxipulseThemeColor;
          font-size: 12px;
          font-weight: 600;
        }

        .p-menuitem-icon {
          &.fa {
            color: $taxiPulseSideBarTextIconActiveColor;
          }
        }
      }
    }
  }

  .p-panelmenu {
    .fa {
      color: $sidebarIconColor;
      font-size: 20px;
      margin-right: 12px;
    }

    .p-panelmenu-header {
      a {
        background: transparent;
        border: 0;
        color: $sidebarMenuTextColor;
        display: inline-flex;
        height: 40px;
        padding: 12px 16px 10px;
        width: 100%;

        .pi-fw {
          display: none;
        }

        &.p-state-active {
          background: #000;
        }
      }

      &:hover {
        background-color: $themeSecondaryHoverColor;
        border-color: $themeSecondaryHoverColor;
      }

      &:active {
        background-color: $themeSecondaryHoverColor;
        border-color: $themeSecondaryHoverColor;

        a {
          color: $white;
        }
      }

      .p-panelmenu-header-link {
        chevronrighticon {
          &.p-icon-wrapper {
            display: none;
          }
        }

        chevrondownicon {
          &.p-icon-wrapper {
            display: none;
          }
        }

        .p-menuitem-icon {
          background-image: url('/assets/images/temp/sprite_v222.png');
          background-repeat: no-repeat;
          color: #FFF;
          height: 20px;
          width: 25px;

          &.fa-driver-time-menu {
            background-image: url('/assets/images/temp/path.svg');
            background-repeat: no-repeat;
            color: #FFF;
            height: 20px;
            width: 25px;

            &.p-state-active {
              background-image: url('/assets/images/temp/path-active.svg');
            }
          }
          //   }
          // }

          // .p-panelmenu-header-link {
          //   // .p-panelmenu-icon.pi-chevron-right {
          //   //   display: none;
          //   // }

          //   .p-menuitem-icon {

          &.fa-dashboard-menu {
            //dashboard
            background-position: -31px -32px;
          }

          &.fa-booking-menu {
            // Bookings
            background-position: -31px -128px;
          }

          &.fa-passenger-menu {
            // passengers
            background-position: -31px -273px;
          }

          &.fa-provider-menu {
            // provider
            background-position: -33px -848px;
          }

          &.fa-emergency-menu {
            // emergency
            background-position: -31px -174px;
          }

          &.fa-scheduler-menu {
            // scheduler
            background-position: -81px -128px;
          }

          &.fa-driver-menu {
            // Drivers
            background-position: -31px -320px;
          }

          &.fa-vehicle-menu {
            // Vehicles
            background-position: -31px -225px;
          }

          &.fa-cities-menu {
            // Cities
            background-position: -31px -416px;
          }

          &.fa-app-setting-menu {
            // App Settings
            background-position: -31px -513px;
          }

          &.fa-user-management-menu {
            // User Mangement
            background-position: -31px -752px;
          }

          &.fa-operator-management-menu {
            // operator Mangement
            background-position: -31px -752px;
          }

          &.fa-marketing-menu {
            // Marketings
            background-position: -82px -896px;
          }

          &.fa-setting-menu {
            // Settings
            background-position: -81px -32px;
          }

          &.fa-report-menu {
            // Reports
            background-position: -81px -416px;
          }

          &.fa-company-menu {
            // Companies
            background-position: -31px -464px;
          }
        }

        &.p-menuitem-link-active {
          background: $themeSecondaryHoverColor;
          border-color: $themeSecondaryHoverColor;
          box-shadow: inset 3px 0 0 0 $taxipulseThemeColor;
          color: $taxiPulseSideBarTextIconActiveColor;
          font-weight: 600;

          .p-menuitem-icon {
            background-image: url('/assets/images/temp/sprite_v2.png');
            background-repeat: no-repeat;
            height: 20px;
            width: 25px;

            &.fa-dashboard-menu {
              //dashboard
              background-position: -141px -32px;
            }

            &.fa-booking-menu {
              // Bookings
              background-position: -141px -128px;
            }

            &.fa-passenger-menu {
              // passengers
              background-position: -141px -273px;
            }

            &.fa-provider-menu {
              // provider
              background-position: -143px -848px;
            }

            &.fa-emergency-menu {
              // emergency
              background-position: -141px -174px;
            }

            &.fa-scheduler-menu {
              // scheduler
              background-position: -191px -128px;
            }

            &.fa-driver-menu {
              // Drivers
              background-position: -141px -320px;
            }

            &.fa-vehicle-menu {
              // Vehicles
              background-position: -141px -225px;
            }

            &.fa-cities-menu {
              // Cities
              background-position: -141px -416px;
            }

            &.fa-app-setting-menu {
              // App Settings
              background-position: -141px -513px;
            }

            &.fa-user-management-menu {
              // User Mangement
              background-position: -141px -752px;
            }

            &.fa-operator-management-menu {
              // operator Mangement
              background-position: -31px -752px;
            }

            &.fa-marketing-menu {
              // Marketings
              background-position: -191px -896px;
            }

            &.fa-setting-menu {
              // Settings
              background-position: -191px -32px;
            }

            &.fa-report-menu {
              // Reports
              background-position: -191px -416px;
            }

            &.fa-company-menu {
              // Companies
              background-position: -142px -464px;
            }
          }
        }

        &:hover {
          background-color: $themeSecondaryHoverColor;
          border: 0;
          box-shadow: inset 3px 0 0 0 $taxipulseThemeColor;
          color: #131212;
        }
      }
    }

    .p-panelmenu-content-wrapper {
      .p-panelmenu-content {
        border: 0;
        padding: 0;

        .p-menuitem {
          border-radius: 0;

          .p-menuitem-link {
            color: #000;
            padding-left: 30px;

            &:hover {
              background-color: $themeSecondaryHoverColor;

              .p-menuitem-text {
                color: #000;
              }
            }
          }
        }
      }

      .p-menuitem-icon {
        background-image: url('/assets/images/temp/sprite_v222.png');
        background-repeat: no-repeat;
        height: 20px;
        width: 25px;

        &.fa-about-company-menu {
          // About Company
          background-position: -32px -560px;
        }

        &.fa-faq-menu {
          // FAQ
          background-position: -32px -608px;
        }

        &.fa-term-condition-menu {
          // Term & conditions
          background-position: -33px -705px;
        }

        &.fa-privacy-policy-menu {
          // Privacy Policy
          background-position: -33px -657px;
        }

        &.fa-manage-user-menu {
          // Manage Users
          background-position: -33px -803px;
        }

        &.fa-manage-operator-menu {
          background-position: -33px -803px;
        }

        &.fa-manage-role-menu {
          // Manage Roles
          background-position: -33px -848px;
        }

        &.fa-promocode-menu {
          // Promocode
          background-position: -81px -177px;
        }

        &.fa-notification-menu {
          // Notifications
          background-position: -32px -895px;
        }

        &.fa-segment-menu {
          // Segments
          background-position: -33px -705px;
        }

        &.fa-time-setting-menu {
          // Time settings
          background-position: -81px -81px;
        }

        &.fa-vehicle-type-menu {
          // Vehicle Type
          background-position: -81px -128px;
        }

        &.fa-reference-setting-menu {
          // Reference Setting
          background-position: -82px -224px;
        }

        &.fa-member-login-menu {
          // Reference Setting
          background-position: -82px -224px;
        }

        &.fa-redius-menu {
          // Redius Settings
          background-position: -81px -272px;
        }

        &.fa-tax-menu {
          // Manage Taxes
          background-position: -82px -320px;
        }

        &.fa-appointment-menu {
          background-position: -82px -320px;
        }

        &.fa-ride-share-menu {
          // Ride Share Settings
          background-position: -81px -366px;
        }

        &.fa-driver-login-logout-report-menu {
          // Driver login logout
          background-position: -82px -465px;
        }

        &.fa-driver-on-duty-report-menu {
          // Driver on duty
          background-position: -82px -512px;
        }

        &.fa-driver-mileage-menu {
          // Driver mileage
          background-position: -82px -560px;
        }

        &.fa-driver-performace-menu {
          // Driver performance
          background-position: -80px -608px;
        }

        &.fa-car-mileage-menu {
          //car Mileage
          background-position: -82px -658px;
        }

        &.fa-refferal-credit-menu {
          // Refferal Credits
          background-position: -82px -702px;
        }

        &.fa-driver-income-menu {
          // Driver Income
          background-position: -82px -930px;
        }

        &.fa-company-income-menu {
          // Company Income
          background-position: -83px -848px;
        }

        &.fa-driver-feedback-menu {
          // Driver feedback
          background-position: -83px -961px;
        }

        &.fa-passenger-feedback-menu {
          // Passenger feedback
          background-position: -83px -756px;
        }

        &.fa-admin-topup-menu {
          // Admin Topup
          background-position: -82px -702px;
        }

        &.fa-admin-transaction-menu {
          // Admin Transaction
          background-position: -82px -930px;
        }
      }

      .p-menuitem-link {
        color: $white;
        display: inline-flex;
        padding-left: 25px;
        width: 100%;

        &.p-state-active {
          .p-menuitem-icon {
            background-image: url('/assets/images/temp/sprite_v222.png');
            background-repeat: no-repeat;
            height: 20px;
            width: 25px;

            &.fa-about-company-menu {
              // About Company
              background-position: -142px -560px;
            }

            &.fa-faq-menu {
              // FAQ
              background-position: -142px -608px;
            }

            &.fa-term-condition-menu {
              // Term & conditions
              background-position: -143px -705px;
            }

            &.fa-privacy-policy-menu {
              // Privacy Policy
              background-position: -143px -657px;
            }

            &.fa-manage-user-menu {
              // Manage Users
              background-position: -143px -803px;
            }

            &.fa-manage-operator-menu {
              background-position: -143px -803px;
            }

            &.fa-manage-role-menu {
              // Manage Roles
              background-position: -143px -848px;
            }

            &.fa-promocode-menu {
              // Promocode
              background-position: -191px -177px;
            }

            &.fa-notification-menu {
              // Notifications
              background-position: -142px -895px;
            }

            &.fa-segment-menu {
              // Segments
              background-position: -143px -705px;
            }

            &.fa-time-setting-menu {
              // Time settings
              background-position: -191px -81px;
            }

            &.fa-vehicle-type-menu {
              // Vehicle Type
              background-position: -191px -128px;
            }

            &.fa-reference-setting-menu {
              // Reference Setting
              background-position: -192px -224px;
            }

            &.fa-member-login-menu {
              // Reference Setting
              background-position: -192px -224px;
            }

            &.fa-redius-menu {
              // Redius Settings
              background-position: -191px -272px;
            }

            &.fa-tax-menu {
              // Manage Taxes
              background-position: -192px -320px;
            }

            &.fa-appointment-menu {
              // Manage Taxes
              background-position: -192px -320px;
            }

            &.fa-ride-share-menu {
              // Ride Share Settings
              background-position: -191px -366px;
            }

            &.fa-driver-login-logout-report-menu {
              // Driver login logout
              background-position: -192px -465px;
            }

            &.fa-driver-on-duty-report-menu {
              // Driver on duty
              background-position: -192px -512px;
            }

            &.fa-driver-mileage-menu {
              // Driver mileage
              background-position: -192px -560px;
            }

            &.fa-driver-performace-menu {
              // Driver performance
              background-position: -190px -608px;
            }

            &.fa-car-mileage-menu {
              //car Mileage
              background-position: -192px -658px;
            }

            &.fa-refferal-credit-menu {
              // Refferal Credits
              background-position: -192px -702px;
            }

            &.fa-driver-income-menu {
              // Driver Income
              background-position: -191px -930px;
            }

            &.fa-company-income-menu {
              // Company Income
              background-position: -193px -848px;
            }

            &.fa-driver-feedback-menu {
              // Driver feedback
              background-position: -192px -961px;
            }

            &.fa-passenger-feedback-menu {
              // Passenger feedback
              background-position: -193px -756px;
            }

            &.fa-admin-topup-menu {
              // Admin Topup
              background-position: -192px -702px;
            }

            &.fa-admin-transaction-menu {
              // Admin Transaction
              background-position: -191px -930px;
            }

            &.fa-global-time-menu {
              background-image: url('/assets/images/temp/global-time-active.svg');
            }

            &.fa-schedule-menu {
              background-image: url('/assets/images/temp/schedule-active.svg');
            }

            &.fa-assign-driver-menu {
              background-image: url('/assets/images/temp/schedule-active.svg');
            }
          }
        }

        // .p-menuitem-icon {
        //   &.fa-global-time-menu {
        //     background-image: url('/assets/images/temp/global-time.svg');
        //     background-repeat: no-repeat;
        //     color: #FFF;
        //     height: 20px;
        //     width: 25px;
        //   }

        //   &.fa-schedule-menu {
        //     background-image: url('/assets/images/temp/schedule.svg');
        //     background-repeat: no-repeat;
        //     color: #FFF;
        //     height: 20px;
        //     width: 25px;
        //   }

        //   &.fa-assign-driver-menu {
        //     background-image: url('/assets/images/temp/schedule.svg');
        //     background-repeat: no-repeat;
        //     color: #FFF;
        //     height: 20px;
        //     width: 25px;
        //   }
        // }
        // }

        // .p-menuitem-link {

      }
    }
  }

  a {
    color: $sidebarMenuHoverTextColor;
  }

  // .p-state-default {
    background: $taxiPulseSecondaryColor;
    border: 1px solid $taxiPulseSecondaryColor;
    color: $sidebarMenuTextColor;
  // }

  .fa-caret-right,
  .fa-caret-down {
    &::before {
      content: '';
    }
  }

  .p-panelmenu-icon {
    &.fa {
      display: none;
      font-size: 18px;
    }
  }
}

.header-panel {
  background: #F5F6FA;
  display: flex;
  height: 73px;

  .company-logo {
    background-color: #FFF;
    height: 63px;
    padding: 11px;
    text-align: center;
  }

  p-menu {
    .p-menu {
      &.p-menu-dynamic {
        top: 63px !important; // sass-lint:disable-line no-important
      }
    }
  }
}

.main-panel {
  background: #EFF2F7;
  min-height: 92vh;

  .right-panel {
    padding: 15px 25px;
  }
}

@media screen and (min-width: 1600px) {
  .left-panel {
    width: 15%;
  }

  .right-panel {
    width: 85%;
  }

  .closed {
    .right-panel {
      width: calc(100% - 60px);
    }
  }
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .left-panel {
    width: 20%;
  }

  .right-panel {
    width: 80%;
  }

  .closed {
    .right-panel {
      width: calc(100% - 60px);
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .left-panel {
    width: 22%;
  }

  .right-panel {
    width: 78%;
  }

  .closed {
    .right-panel {
      width: calc(100% - 60px);
    }
  }
}

@media screen and (max-width: 1000px) and (min-width: 768px) {
  .left-panel {
    width: 30%;
  }

  .right-panel {
    width: 70%;
  }

  .closed {
    .right-panel {
      width: calc(100% - 60px);
    }
  }
}

@media screen and (max-width: 767px) {
  .header-panel {
    display: block;

    .app-header {
      display: none;
    }

    p-menu {
      .p-menu {
        &.p-menu-dynamic {
          top: 125px !important; // sass-lint:disable-line no-important
        }
      }
    }
  }

  .main-panel {
    display: block;
    margin-top: 125px;

    .right-panel {
      float: left;
    }

    .sidebar {
      min-height: auto;
      width: 100%;
    }
  }

  .nav-right-menu {
    float: auto;
    width: 100%;

    .profile-menu-button {
      float: right;
    }
  }

  .profile-panel {
    width: 100%;
  }
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link { 
  padding-left: 30px;
  background: #FFF;
}