body {
  .p-inputtext {
    &.ng-dirty {
      &.ng-invalid {
        border: 0;
        border-bottom: 1px solid #ECEDF3;

        &:hover {
          border-color: $stateFocusBorderColor;
        }
      }
    }
  }
}

.signin-box-container {
  background: url('/assets/images/temp/background.png') no-repeat fixed;
  background-position: 0% 25%;
  background-size: cover;
  display: table;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;

  .signin-box-inner-container {
    display: table-cell;
    vertical-align: middle;

    .p-panel {
      .p-panel-titlebar {
        padding: 0;
      }

      .p-panel-content {
        background-color: #FFF;
        border: 0;
        border-radius: 5px;
        color: #333;
        padding: 30px 30px;
      }
    }

    .textbox-border-bottom {
      border: 0;
      border-bottom: 1px solid #F4F5FA;
    }

    label {
      color: $defaultThemeColor;
      font-size: 14px;
    }

    .switch-btn {
      color: #D4D6DA;
      cursor: default;
      font-size: 20px;
      font-weight: bold;
      padding: 27px 0;
      text-align: center;
    }

    .signin-box {
      margin: 0 auto;
      position: relative;
    }

    .logo-box {
      margin: 0 auto;
      position: relative;
    }

    .bottom-border {
      border: 0;
      border-bottom: 1px solid #ECEDF3;
    }

    .forgot-password-link {
      color: $defaultTextColor;
      float: right;
      font-size: 12px;

      label {
        cursor: pointer;
      }
    }

    .sign-in-buttons {
      right: -65px;
      width: 100%;
    }

    .margin-top-20 {
      margin-top: 20px;
    }

    input {

      &:focus {
        border: 0;
        border-bottom: 1px solid #ECEDF3;
      }
    }

    .read-more-button {
      background: transparent;
      border: 1px solid $white;
      border-radius: 18px;
      width: 75%;
    }

    .forgot-pass-form {
      background-color: #FFF;
      border-radius: 8px;
      margin: auto;
      min-height: 450px;
      padding: 50px 30px;
      width: 420px;

      .buttom-text {
        color: #808080;
        font-size: 13px;
        margin: 0;
        margin-top: 10px;
      }
    }

    .selected-menu {
      background-color: $defaultThemeColor;
      height: 4px;
      padding: 0;
    }

    .float-left {
      float: left;
    }

    .float-right {
      float: right;
    }

    h2 {
      color: $white;
      font-weight: normal;
      line-height: 2;
    }

    h4 {
      color: $white;
      font-weight: normal;
      line-height: 2;
    }

    .term-condition {
      margin: 15px 0;
    }
  }
}

.powerful-dispatching {
  color: #FFF;
  font-size: 32px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  height: 90px;
  left: -100px;
  letter-spacing: normal;
  line-height: 1.41;
  margin: auto;
  text-align: center;
  top: -95px;
  width: 810px;
}

.nemt-pulse-text {
  color: #2B2B33;
  font-size: 90px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  height: 100px;
  letter-spacing: normal;
  line-height: normal;
  margin: 0 auto;
  opacity: .1;
  text-align: center;
  width: 550px;
}

.sign-comatiner {
  margin: 0 auto;
  width: 550px;
}
