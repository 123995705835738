.time-setting-spinner {
  .ui-spinner {
    width: 65px;
    left: 10px;
    position: relative;

    input {
      padding-left: 5px;
    }

  }
}

.time-setting-group-name {
  color: #8491A6;
  font-size: 15px;
  font-weight: bold;
}

.time-settings {
  margin: 10px;
  padding: 0;

  .p-panel-titlebar {
    background: $sideBarHoverBgColor;
    border: 0;
    border-radius: 0;
    color: $white;
    font-size: 15px;
    padding: 8px 16px;

    .p-panel-titlebar-icon {
      &:hover {
        background: $secondaryThemeColor;
        border: 1px solid $secondaryThemeColor;
      }
    }
  }

  .p-panel-content {
    overflow: hidden;

    .time-setting-block {
      background: $bodyBgColor;
      border: 1px solid $contentBorderColor;
      padding: 10px;
    }
  }
}

.time-settings-unique-block {
  padding: 0 15px;

  .time-setting-block {
    .form-field {
      label {
        opacity: .8;
      }
    }

    .time-block-section {
      border-bottom: 1px solid #C6C6C6;

      .form-control {
        input {
          border: 0;

          &:disabled {
            background: #FFF;
            border: 0;
          }

          &:read-only {
            background: #FFF;
            border: 0;
          }
        }
      }

      .form-buttons {
        button {
          float: none;
        }

        .p-button-text-only {
          .p-button-text {
            padding: 6px 15px !important; // sass-lint:disable-line no-important
          }
        }
      }

      .time-setting-description {
        padding-top: 13px;
      }
    }
  }

  &:last-child {
    .time-setting-block {
      .time-block-section {
        border-bottom: 0;
      }
    }
  }
}
